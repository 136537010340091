import React from 'react'
import { useState, useEffect } from 'react'

function Contact() {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        border: '0px solid rgb(229, 231, 235)',
      }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          border: '0px solid rgb(229, 231, 235)',
          display: 'flex',
          WebkitBoxPack: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            border: '0px solid rgb(229, 231, 235)',
            display: 'grid',
            gridTemplateColumns: 'auto',
            padding: '40px 0px 80px',
            width: '100%',
            maxWidth: '960px',
            margin: '0px auto',
          }}
        >
          <div
            style={{
              paddingLeft: '10px',
              boxSizing: 'border-box',
              border: '0px solid rgb(229, 231, 235)',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                border: '0px solid rgb(229, 231, 235)',
                display: 'flex',
                marginBottom: '30px',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  boxSizing: 'border-box',
                  border: '0px solid rgb(229, 231, 235)',
                  display: 'flex',
                  flexDirection: 'column',
                  WebkitBoxAlign: 'stretch',
                  alignItems: 'stretch',
                  WebkitBoxPack: 'center',
                  justifyContent: 'center',
                  margin: '0px auto 30px',
                  width: '100%',
                  maxWidth: '660px',
                  textAlign: 'center',
                }}
              >
                <h1
                  style={{
                    boxSizing: 'border-box',
                    margin: '0px 0px 20px',
                    fontSize: '52px',
                    fontWeight: 700,
                    border: '0px solid rgb(229, 231, 235)',
                    marginBottom: '20px',
                    lineHeight: '65px',
                    color: '#fff',
                  }}
                >
                  Contact us
                </h1>
              </div>{' '}
              {/* */}
              <div
                style={{
                  boxSizing: 'border-box',
                  border: '0px solid rgb(229, 231, 235)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  WebkitBoxPack: 'center',
                  justifyContent: 'center',
                  margin: '0px auto 30px',
                  width: '100%',
                  maxWidth: '660px',
                  textAlign: 'center',
                }}
              >
                <h3
                  style={{
                    boxSizing: 'border-box',
                    margin: '0px 0px 20px',
                    fontSize: '24px',
                    fontWeight: 400,
                    border: '0px solid rgb(229, 231, 235)',
                    textAlign: 'center',
                    padding: '0px',
                    color: '#fff',
                  }}
                >
                  Contact Information
                </h3>
                <p
                  style={{
                    boxSizing: 'border-box',
                    margin: '0px',
                    border: '0px solid rgb(229, 231, 235)',
                    color: 'rgb(153, 153, 153)',
                    fontWeight: 400,
                    fontSize: '16px',
                    marginBottom: '0px',
                    letterSpacing: '0.32px',
                  }}
                >
                  {/* */}
                  <a
                    href="mailto:support@aiflowchart.io"
                    style={{
                      boxSizing: 'border-box',
                      color: 'rgb(255, 45, 96)',
                      textDecoration: 'none solid rgb(255, 45, 96)',
                      border: '0px solid rgb(229, 231, 235)',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      transition: 'color 0.15s ease-in-out 0s',
                    }}
                  >
                    support@aiflowchart.io
                  </a>
                </p>
                <p
                  style={{
                    boxSizing: 'border-box',
                    margin: '0px',
                    border: '0px solid rgb(229, 231, 235)',
                    color: 'rgb(153, 153, 153)',
                    fontWeight: 400,
                    fontSize: '16px',
                    marginBottom: '0px',
                    letterSpacing: '0.32px',
                  }}
                >
                  {/* */}
                  <a
                    href="mailto:support@aiflowchart.io"
                    style={{
                      boxSizing: 'border-box',
                      color: 'rgb(255, 45, 96)',
                      textDecoration: 'none solid rgb(255, 45, 96)',
                      border: '0px solid rgb(229, 231, 235)',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      transition: 'color 0.15s ease-in-out 0s',
                    }}
                  >
                    vibinreji666@gmail.com
                  </a>
                </p>
              </div>
              <div style={styles.container}>
                <h2 style={styles.heading}>Interested in Similar Projects?</h2>
                <p style={styles.paragraph}>
                  Hello there! If you've stumbled upon our platform and found
                  our services intriguing, you might be wondering if we're
                  available for similar projects tailored to your needs. The
                  answer is yes! We're ready and eager to take on new challenges
                  and work with individuals or businesses who seek solutions
                  similar to what we offer.
                </p>
                <p style={styles.paragraph}>
                  Whether you're looking for custom software development, SaaS
                  solutions, or any other related services, we're here to help.
                  Simply drop us an email at{' '}
                  <a
                    href="mailto:eversolutions24@gmail.com"
                    style={styles.link}
                  >
                    eversolutions24@gmail.com
                  </a>{' '}
                  with details about your project requirements, and we'll get
                  back to you promptly to discuss how we can assist you.
                </p>
                <p style={styles.paragraph}>
                  Don't hesitate to reach out! We're excited about the
                  opportunity to collaborate and bring your ideas to life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const styles = {
  container: {
    backgroundColor: '#222',
    color: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '600px',
    margin: '0 auto',
    textAlign: 'center',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '15px',
  },
  link: {
    color: '#00a8ff',
    textDecoration: 'none',
  },
}
export default Contact
