import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const BannerContainer = styled.div`
  background-color: #333;
  padding: 10px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const BannerMessage = styled.p`
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

const LearnMoreLink = styled.a`
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 10px;
`

const CloseButton = styled.button`
  margin-left: 10px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  @media (max-width: 768px) {
    position: static;
    margin-left: 0;
  }
`

const PromoBanner = () => {
  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    const bannerClosed = localStorage.getItem('bannerClosed')
    if (bannerClosed) {
      setShowBanner(false)
    }
  }, [])

  const handleCloseBanner = () => {
    setShowBanner(false)
    localStorage.setItem('bannerClosed', 'true')
  }

  return showBanner ? (
    <BannerContainer>
      <BannerMessage>
        Introducing resumeanalyser.com - AI-powered resume analysis and cover
        letter generation tool.
        <LearnMoreLink
          href="https://resumeanalyser.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </LearnMoreLink>
      </BannerMessage>
      <CloseButton onClick={handleCloseBanner}>&times;</CloseButton>
    </BannerContainer>
  ) : null
}

export default PromoBanner
