import React, { useState, useRef, useEffect, useCallback } from 'react'
import MDEditor from '@uiw/react-md-editor'
import mermaid from 'mermaid'
import toImg from 'react-svg-to-image'
import { commonService } from '../Network/services/commonService'
import { toast } from 'react-toastify'
import { Dropzone, FileMosaic } from '@files-ui/react'
import { useHistory } from 'react-router-dom'
import PrismaZoom from 'react-prismazoom'
import * as htmlToImage from 'html-to-image'
import BarLoader from 'react-spinners/BarLoader'
import imageCompression from 'browser-image-compression'
import ReactGA from 'react-ga'
import Watermark from '@uiw/react-watermark'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import ColorPickerModal from '../components/ColorPickerModal'
import NewDownloadModal from '../components/NewDownloadModal'
import TagButton from '../components/TagButton'
import { BUTTON_TEXT } from '../components/constants' // Import button text constants
import MessageInputBar from '../components/MessageInputBar'
import { Button } from 'primereact/button'
import LoadingBar from 'react-top-loading-bar'
import RoundButton from '../components/RoundButton'
import { Modal, Form } from 'antd'
import rgbHex from 'rgb-hex'
import Select from 'react-select'
import {
  faSearchPlus,
  faSearchMinus,
  faUndo,
} from '@fortawesome/free-solid-svg-icons'

import {
  Excalidraw,
  MainMenu,
  Footer,
  Sidebar,
  exportToBlob,
  useDevice,
} from '@excalidraw/excalidraw'

import { parseMermaidToExcalidraw } from '@excalidraw/mermaid-to-excalidraw'
import { convertToExcalidrawElements } from '@excalidraw/excalidraw'

const MAX_RETRY_LIMIT = 4
const RETRY_DELAY_MS = 1000 // 1 second (adjust as needed)
const randomid = () => parseInt(String(Math.random() * 1e15), 10).toString(36)
const Code = ({
  inline,
  children = [],
  className,
  demo,
  prismaZoom,
  onZoomChange,
  onRenderFailure,
  setLoadMermaid,
  loadMermaid,
  downloadVisible,

  ...props
}) => {
  const demoid = useRef(`dome${randomid()}`)
  const code = getCode(children)
  const [allowWheel, setAllowWheel] = useState(false)
  const transformRef = useRef(null)
  const handle = useCallback(() => {
    // if (loadMermaid) {
    myFunction(demo, code)
    setLoadMermaid(false)
    // }
  }, [demo, code, loadMermaid])
  const [zoomState, setZoomState] = useState({
    scale: 1.5,
    positionX: 100,
    positionY: 100,
  })
  const myFunction = async (demo, code) => {
    if (demo.current) {
      try {
        const { svg } = await mermaid.render(demoid.current, code)
        demo.current.innerHTML = svg
        demo.current.removeAttribute('data-processed')
        setAllowWheel(true)

        if (loadMermaid) {
          onRenderFailure(false)
        }
      } catch (error) {
        if (code) {
          demo.current.innerHTML = ''
          setAllowWheel(false)
          if (loadMermaid) {
            onRenderFailure(true)
          }
        }
      }
    }
  }
  const handleZoomIn = () => {
    if (transformRef.current) {
      transformRef.current.zoomIn()
    }
  }

  const handleZoomOut = () => {
    if (transformRef.current) {
      transformRef.current.zoomOut()
    }
  }

  const handleReset = () => {
    if (transformRef.current) {
      transformRef.current.resetTransform()
    }
  }

  // Update zoom state when it changes
  useEffect(() => {
    if (transformRef.current) {
      transformRef.current.setTransform({
        scale: zoomState.scale,
        positionX: zoomState.positionX,
        positionY: zoomState.positionY,
      })
    }
  }, [zoomState])

  useEffect(() => handle(), [handle])
  const transformOptions = {
    initialScale: 1,
    minScale: 0.5,
    maxScale: 2,
  }
  if (
    typeof code === 'string' &&
    typeof className === 'string' &&
    /^language-mermaid/.test(className.toLocaleLowerCase())
  ) {
    return (
      // <PrismaZoom
      //   onZoomChange={onZoomChange}
      //   ref={prismaZoom}
      //   allowWheel={allowWheel}
      // >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            position: 'relative',
            zIndex: 99999999,
          }}
        >
          <button
            style={{ backgroundColor: '#161a1d', marginRight: 5 }}
            onClick={handleZoomIn}
          >
            <FontAwesomeIcon icon={faSearchPlus} />
          </button>
          <button
            style={{ backgroundColor: '#161a1d', marginRight: 5 }}
            onClick={handleZoomOut}
          >
            <FontAwesomeIcon icon={faSearchMinus} />
          </button>
          <button
            style={{ backgroundColor: '#161a1d', marginRight: 5 }}
            onClick={handleReset}
          >
            <FontAwesomeIcon icon={faUndo} />
          </button>
        </div>
        {!downloadVisible && (
          <TransformWrapper
            initialScale={2}
            centerZoomedOut={true}
            minScale={0.5}
            maxScale={12}
            panning={{ excluded: ['input'] }}
            doubleClick={{ disabled: true }}
            initialPositionX={100}
            initialPositionY={100}
            ref={transformRef}
          >
            <TransformComponent>
              <pre className="language-mermaid">
                <div className="containerNew">
                  <code id="demo" ref={demo}>
                    <code id={demoid.current} style={{ display: 'none' }} />
                  </code>
                </div>
              </pre>
            </TransformComponent>
          </TransformWrapper>
        )}

        {downloadVisible && (
          <pre className="language-mermaid">
            <div className="containerNew">
              <code id="demo" ref={demo}>
                <code id={demoid.current} style={{ display: 'none' }} />
              </code>
            </div>
          </pre>
        )}
      </div>

      // </PrismaZoom>
    )
  }
  return <code className={String(className)}>{children}</code>
}
const DummyCode = () => {
  return <div></div>
}
const getCode = (arr = []) =>
  arr
    .map((dt) => {
      if (typeof dt === 'string') {
        return dt
      }
      if (dt.props && dt.props.children) {
        return getCode(dt.props.children)
      }
      return false
    })
    .filter(Boolean)
    .join('')
function AppView() {
  const [files, setFiles] = React.useState([])
  const [animationClass, setAnimationClass] = useState('')
  const [value, setValue] = useState('')
  const [content, setContent] = useState(null)
  const [renderFailureCount, setRenderFailureCount] = useState(0)
  const ref = useRef(null)
  const [mermaidCode, setMermaidCode] = useState('')
  const [visible, setVisible] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showFileUpload, setShowFileUpload] = useState(false)
  const [selectedTagButton, setSelectedTagButton] = useState('')
  const [downloadVisible, setDownloadVisible] = useState(false)
  const [loadMermaid, setLoadMermaid] = useState(false)
  const [isPremium, setIsPremium] = useState(false)
  const [submited, setSubmited] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [callbackData, setCallbackData] = useState(null)

  const [textareaValue, setTextareaValue] = useState('')
  const [form] = Form.useForm()
  const [allvalue, setAllValue] = useState('')
  const [svg, setSvg] = useState(null)
  const [colorUpdate, setSetColorUpdate] = useState('')
  const [colorPickerColor, setColorPickerColor] = useState('')
  const history = useHistory()

  const [primaryColor, setPrimaryColor] = useState('#BB2528')
  const [primaryTextColor, setPrimaryTextColor] = useState('#fff')
  const [lineColor, setLineColor] = useState('#d3d3d3')
  const [buttonTypeClick, setButtonTypeClick] = useState('primaryColor')
  const [selectedOption, setSelectedOption] = useState({
    value: 'dark',
    label: 'Dark',
  })
  const [theme, setTheme] = useState('dark')
  const [enableCustom, setEnableCustom] = useState(false)
  const [selectedDownloadOption, setSelectedDownloadOption] = useState({
    value: 'png',
    label: 'PNG',
  })
  const [jpegBackgroundColor, setJpegBackgroundColor] = useState(
    'rgb(255, 255, 255)',
  )
  const [loading, setLoading] = useState(false)

  const demo = useRef()
  const containerRef = useRef()
  const buttonRef = useRef(null)
  const showAnimation = (value) => {
    if (value) {
      setAnimationClass('animation-btn animated-ui tab-content-loading')
    } else {
      setAnimationClass('')
    }
  }
  // useEffect(() => {
  //   // Send the conversion event to Google Analytics
  //   ReactGA.event({
  //     category: 'Sign-up',
  //     action: 'Sign-up',
  //     label: 'YOUR-LABEL', // Optional: Add a label if needed
  //     value: 1, // Optional: Add a value if needed
  //     send_to: 'AW-10810690229/NeO_CJvLpvYYELWV-KIo', // Replace with your actual send_to value
  //   })
  // }, [])
  const handleSubmit = async (e) => {
    // Prevent the default submit and page reload
    e.preventDefault()
    ReactGA.event({
      category: 'Conversion',
      action: 'Sign-up',
      label: 'Sign-up', // Optional: Add a label if needed
      value: 1, // Optional: Add a value if needed
      send_to: 'AW-10810690229/NeO_CJvLpvYYELWV-KIo', // Replace with your actual send_to value
    })
    const message = await triggerAPI()
    setSubmited(true)
    setContent(message)
  }

  const triggerAPIPromis = () => {
    // Simulate an asynchronous API call using a Promise.
    return new Promise((resolve, reject) => {
      // Simulate success after a short delay (you can replace this with actual API logic).
      triggerAPI()
    })
  }
  const triggerAPI = async () => {
    return new Promise(async (resolve, reject) => {
      let formData = new FormData() // formdata object
      let file

      if (files.length > 0) {
        if (
          files[0].type == 'image/png' ||
          files[0].type == 'jpg' ||
          files[0].type == 'image/jpeg'
        ) {
          const imageFile = files[0].file

          try {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 600,
              useWebWorker: true,
            }
            const compressedFile = await imageCompression(imageFile, options)

            file = new File([compressedFile.file], compressedFile.name, {
              lastModified: new Date().getTime(),
              webkitRelativePath: '',
              type: compressedFile.type,
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          file = new File([files[0].file], files[0].name, {
            lastModified: new Date().getTime(),
            webkitRelativePath: '',
            type: files[0].type,
          })
        }
        formData.append('file', file)
        formData.append('prompt', '')
      } else {
        formData.append('prompt', textareaValue)
      }
      formData.append('type', selectedTagButton)

      if (files.length === 0 && textareaValue === '') {
        // reject(
        //   new Error('Type something or attach a file to generate a flowchart'),
        // )
        handlePause()
        toast.success(
          'Type something or attach a file to generate a flowchart',
          {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          },
        )
      } else {
        try {
          ref.current.continuousStart()
        } catch (err) {}
        setLoading(true)
        showAnimation(true)
        commonService
          .diagram('diagram', formData)
          .then(async (res) => {
            if (res.success === true) {
              resolve(res.message)
            } else {
              if (
                res.message === 'invalid token' ||
                res.message === 'invalid user'
              ) {
                toast.success('Session Expired. Please login again', {
                  type: 'error',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                })
                onLogout()
                history.push('/login')
                // reject(new Error('Session Expired. Please login again'))
              } else {
                toast.success(res.message, {
                  type: 'error',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                })
                // reject(new Error(res.message))
              }
            }
          })
          .catch((err) => {
            toast.success('Something went wrong! Please try again later!!', {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            reject(err)
          })
          .finally(() => {
            setLoading(false)
            showAnimation(false)
            try {
              ref.current.complete()
            } catch (err) {}
          })
      }
    })
  }
  const triggerAPINew = async (file, text) => {
    return new Promise(async (resolve, reject) => {
      let formData = new FormData() // formdata object
      let fileupload
      if (file) {
        if (
          file.type == 'image/png' ||
          file.type == 'jpg' ||
          file.type == 'image/jpeg'
        ) {
          const imageFile = file

          try {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 600,
              useWebWorker: true,
            }
            const compressedFile = await imageCompression(imageFile, options)

            fileupload = new File([compressedFile.file], compressedFile.name, {
              lastModified: new Date().getTime(),
              webkitRelativePath: '',
              type: compressedFile.type,
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          fileupload = file
        }
        formData.append('file', fileupload)
        formData.append('prompt', '')
      } else {
        formData.append('prompt', text)
      }
      //formData.append('type', selectedTagButton)

      if (file === null && text === '') {
        // reject(
        //   new Error('Type something or attach a file to generate a flowchart'),
        // )
        handlePause()
        toast.success(
          'Type something or attach a file to generate a flowchart',
          {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          },
        )
      } else {
        try {
          ref.current.continuousStart()
        } catch (err) {}
        setLoading(true)
        showAnimation(true)
        commonService
          .diagram('diagram', formData)
          .then(async (res) => {
            if (res.success === true) {
              resolve(res.message)
            } else {
              if (
                res.message === 'invalid token' ||
                res.message === 'invalid user'
              ) {
                toast.success('Session Expired. Please login again', {
                  type: 'error',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                })
                onLogout()
                history.push('/login')
                // reject(new Error('Session Expired. Please login again'))
              } else if (res.message === 'credit expired') {
                toast.success(
                  "You've reached the maximum credit limit. To continue using our service, please subscribe to our premium plan for unlimited access.",
                  {
                    type: 'error',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                  },
                )
                history.push('/pricing')
              } else {
                toast.success(res.message, {
                  type: 'error',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: 0,
                })
                // reject(new Error(res.message))
              }
            }
          })
          .catch((err) => {
            toast.success('Something went wrong! Please try again later!!', {
              type: 'error',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            })
            reject(err)
          })
          .finally(() => {
            setLoading(false)
            showAnimation(false)
            handlePause()

            try {
              ref.current.complete()
            } catch (err) {}
          })
      }
    })
  }

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click()
    }
  }, [])
  useEffect(() => {
    updateuserData()
  }, [])
  const updateCredit = () => {
    commonService
      .CommonService('updatecredit', {})
      .then(async (res) => {})
      .catch((err) => {})
      .finally(() => {})
  }
  const updateuserData = () => {
    commonService
      .CommonService('userdata', {})
      .then(async (res) => {
        if (res.success == true) {
          if (res.user.verified) {
            window.localStorage.setItem('isLoggedIn', true)
            window.localStorage.setItem('token', res.token)
            window.localStorage.setItem('user', JSON.stringify(res.user))
            if (res.user.status == 'active') {
              setIsPremium(true)
            }
          }
        } else {
        }
      })
      .catch((err) => {})
      .finally(() => {})
  }
  useEffect(() => {
    const storedValue = localStorage.getItem('isLoggedIn')

    try {
      const userJSON = localStorage.getItem('user')

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      if (userObject.status == 'active') {
        setIsPremium(true)
      }
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      //setLoggedIn(true)
    }
  }, [])
  const triggerAPI1 = () => {
    // Simulate an asynchronous API call using a Promise.
    return new Promise((resolve, reject) => {
      // Simulate success after a short delay (you can replace this with actual API logic).
      setLoading(true)

      setTimeout(() => {
        setLoading(false)

        resolve('API call success')
      }, 1000) // Simulate a 1-second delay
    })
  }

  const onRenderFailure = async (isFailed) => {
    if (isFailed) {
      if (renderFailureCount < 3) {
        toast.success('Rendering failed... Still retrying. Please hold on.', {
          type: 'warning',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
        // console.log('Rendering failed... Still retrying. Please hold on.')

        if (callbackData.file) {
          const message = await triggerAPINew(callbackData.file, '')
          // Add your logic to send the message and file
          setIsProcessing(true)
          setSubmited(true)
          setContent(message)
        } else {
          const message = await triggerAPINew(null, callbackData.message)
          // Add your logic to send the message and file
          setIsProcessing(true)
          setSubmited(true)
          setContent(message)
        }

        setRenderFailureCount((prevCount) => prevCount + 1)
      } else {
        toast.success(
          'We are unable to render. Please try a different query.',
          {
            type: 'error',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          },
        )
        console.log('Trying to rerender')
        console.log('Maximum retry limit reached. Unable to rerender.')
        setRenderFailureCount(-1) // Set to a value indicating maximum retries reached
      }
    } else {
      // Render success case
      //console.log('Render successful')

      setRenderFailureCount(0) // Set to a value indicating success
    }
  }

  const onLogout = () => {
    window.localStorage.setItem('isLoggedIn', false)
    window.localStorage.setItem('token', '')
    window.localStorage.setItem('user', null)
  }

  function processMermaid(content) {
    extractMermaidCode(content)
    //setMermaidValue(extractMermaidCodeOnly(content))

    updateScene(extractMermaidCodeOnly(content))
  }

  function extractMermaidCodeOnly(content) {
    const mermaidRegex = /\`\`\`mermaid\s*([\s\S]*?)\`\`\`/g

    const mermaidMatches = content.match(mermaidRegex)

    if (mermaidMatches) {
      const mermaidCodeList = mermaidMatches.map((match) => match.trim())

      content = mermaidCodeList.toString()
      // setValue(mermaidCodeList.toString())
    }
    const cleanContent = content.replace(/(mermaid|@|’)/gi, '')
    const cleanedCode = cleanContent.replace(/\[([^[\]]+)\]/g, '["$1"]')

    //const regex = /```([\s\S]*?)```|(Note:[\s\S]*)/gm;
    const regex = /```([\s\S]*?)```/gm
    const match = regex.exec(cleanedCode)
    if (match) {
      return match[1]
    } else {
      return cleanedCode
    }
  }
  function extractMermaidCode(content) {
    const cleanContent = content.toString().replace(/mermaid/gi, '')

    setAllValue(cleanContent)
  }
  useEffect(() => {
    // if (demo.current) {
    //   demo.current.removeAttribute('data-processed')
    // }
    async function updateValueAsync() {
      const architectureData = `
\`\`\`mermaid
graph TD

subgraph Application Layer
    A[Applications]
end

subgraph Framework Layer
    F1[Application Framework]
    F2[Android Runtime (ART)]
    F3[Libraries]
end

subgraph Linux Kernel Layer
    L1[Linux Kernel]
end

A --> F1
F1 --> F2
F1 --> F3
F2 --> L1
L1 --> F3
\`\`\`

The applications are built on top of the application framework, which, in turn, uses the Android runtime and libraries to interact with the Linux kernel for hardware and system-level access.

This layered architecture provides a modular and flexible approach to building Android applications, enabling developers to reuse common functionality and build applications that can run on a wide range of devices.
`
      processMermaid(content)
      setLoadMermaid(true)
      setContent(null)
    }
    if (content) {
      updateValueAsync()
    }
  }, [content])
  const handleDownload = (e) => {
    e.preventDefault()

    if (isPremium) {
      setDownloadVisible(true)
    } else {
      toast.success(
        'Please subscribe to the premium plan to download diagrams.',
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
    }
  }
  const handleColorButtonClick = (buttonType) => {
    if (buttonType == 'primaryColor') {
      setButtonTypeClick('primaryColor')
      setColorPickerColor(primaryColor)
    } else if (buttonType == 'primaryTextColor') {
      setButtonTypeClick('primaryTextColor')
      setColorPickerColor(primaryTextColor)
    } else if (buttonType == 'lineColor') {
      setButtonTypeClick('lineColor')
      setColorPickerColor(lineColor)
    }
  }
  const handleCreate = (values) => {
    try {
      if (values.color !== undefined) {
        if (buttonTypeClick == 'primaryColor') {
          setPrimaryColor('#' + rgbHex(values.color))
        } else if (buttonTypeClick == 'primaryTextColor') {
          setPrimaryTextColor('#' + rgbHex(values.color))
        } else if (buttonTypeClick == 'lineColor') {
          setLineColor('#' + rgbHex(values.color))
        }
        setSetColorUpdate('#' + rgbHex(values.color))
      }
    } catch (error) {}
  }

  const handleDownloadCreate = async (values) => {
    if (isPremium) {
      const elements = excalidrawAPI.getSceneElements()
      const appState = excalidrawAPI.getAppState()
      const mimeType = values === 'png' ? 'image/png' : 'image/jpeg'
      const quality = values === 'jpeg' ? 0.92 : undefined

      const blob = await exportToBlob({
        elements,
        appState: {
          ...appState,
          exportBackground: true,
          exportWithDarkMode: false,
        },
        mimeType,
        quality,
      })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `aiflowchart.${values}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      toast.success(
        'Please subscribe to the premium plan to download diagrams.',
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
    }
  }

  const handleFileUpload = (e) => {
    e.preventDefault()
    //updateuserData()
    setShowFileUpload(true)
  }
  const prismaZoom = useRef(null)
  const zoomCounterRef = useRef(null)

  const updateFiles = (incommingFiles) => {
    //do something with the files
    setFiles(incommingFiles)
    if (incommingFiles.length > 0) {
      setShowFileUpload(true)
    } else {
      setShowFileUpload(false)
    }
    //even your own upload implementation
  }
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id))
  }
  const handleButtonClick = (buttonLabel) => {
    setSelectedTagButton(buttonLabel)
  }
  const buttonGroupStyle = {
    display: 'flex', // Display buttons in a row
    width: '100%', // Make the button group fill the parent div horizontally
    justifyContent: 'center', // Space buttons evenly within the button group
  }

  const buttonStyle = {
    flex: 1, // Allow buttons to equally distribute available space
    margin: '5px', // Add margin around each button
    height: '30px', // Adjust the value to set the desired button height
    textAlign: 'center', // Center-align the text within the buttons
  }
  const centeredText = {
    textAlign: 'center', // Center-align the text within the buttons
  }

  const [excalidrawAPI, setExcalidrawAPI] = useState(null)
  const mermaidCodeex = `
flowchart TD
  A[Christmas] -->|Get money| B(Go shopping)
  B --> C{Let me think}
  C -->|One| D[Laptop]
  C -->|Two| E[iPhone]
  C -->|Three| F[Car]
`

  const updateScene = async (mermaidCodeexcali) => {
    try {
      const { elements, files } = await parseMermaidToExcalidraw(
        mermaidCodeexcali,
        {
          fontSize: 16,
        },
      )
      const excalidrawElements = convertToExcalidrawElements(elements)
      const sceneData = {
        elements: excalidrawElements,
        appState: {
          viewBackgroundColor: '#edf2ff',
        },
      }
      if (excalidrawAPI) {
        excalidrawAPI.updateScene(sceneData)
      }
      excalidrawAPI.scrollToContent(excalidrawAPI.getSceneElements(), {
        fitToContent: true,
      })

      if (files) {
        excalidrawAPI.addFiles(Object.values(files))
      }
      updateCredit()
    } catch (e) {
      onRenderFailure(true)
    }
  }
  const excalidrawWrapperRef = useRef(null)
  const parentContainerRef = useRef(null)

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Disable Cmd+Shift+E on macOS and Ctrl+Shift+E on Windows
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key.toLowerCase() === 'e'
      ) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    // Add event listener to the document
    document.addEventListener('keydown', handleKeyDown)

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const filesRef = React.useRef([])
  const textareaValueRef = React.useRef('')
  const handleSend = async (data) => {
    setCallbackData(data)
    if (data.file) {
      const message = await triggerAPINew(data.file, '')
      // Add your logic to send the message and file
      setIsProcessing(true)
      setSubmited(true)
      setContent(message)
    } else {
      const message = await triggerAPINew(null, data.message)
      // Add your logic to send the message and file
      setIsProcessing(true)
      setSubmited(true)
      setContent(message)
    }
  }
  const handlePause = () => {
    setIsProcessing(false)
    // Add your logic to handle pausing the API call
  }

  const MobileFooter = ({}) => {
    const device = useDevice()
    if (device.editor.isMobile) {
      return (
        <div style={{ width: '100%' }}>
          <MessageInputBar
            onSend={handleSend}
            onPause={handlePause}
            isProcessing={isProcessing}
          />
        </div>
      )
    }
  }
  return (
    <div>
      <div className="container-fluid">
        <div ref={parentContainerRef} className="tab-editor-sec">
          {/* <ul className="nav nav-tabs nav-pills">
            <li className="active">
              <a data-toggle="pill" href="#home" className="active">
                <span
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Flow Chat"
                >
                  <i className="fas fa-sitemap" />
                  &nbsp;Flow Chart
                </span>
              </a>
            </li>
            <li>
              <a data-toggle="pill" href="#menu1">
                <span
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Information"
                >
                  <i className="fab fa-stack-overflow" /> &nbsp;Information
                </span>
              </a>
            </li>
          </ul> */}
          <div className="tab-content">
            <div id="home" className="tab-pane fade in active show">
              <div
                className="excalidraw-wrapper"
                style={{ height: '90vh' }}
                // onWheelCapture={(e) => {
                //   // Stop Excalidraw from hijacking scroll
                //   e.stopPropagation()
                // }}
              >
                <MobileFooter />

                <Excalidraw
                  handleKeyboardGlobally={true}
                  renderTopRightUI={() => {
                    return (
                      <button
                        style={{
                          background: '#70b1ec',
                          border: 'none',
                          color: '#fff',
                          width: 'max-content',
                          fontWeight: 'bold',
                        }}
                        onClick={() => {
                          if (isPremium) {
                            setDownloadVisible(true)
                          } else {
                            toast.success(
                              'Please subscribe to the premium plan to download diagrams.',
                              {
                                type: 'error',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: 0,
                              },
                            )
                          }
                        }}
                      >
                        Download
                      </button>
                    )
                  }}
                  ref={excalidrawWrapperRef}
                  gridModeEnabled={true}
                  detectScroll={false}
                  UIOptions={{
                    dockedSidebarBreakpoint: 200,
                    canvasActions: {
                      saveToActiveFile: false,
                      saveAsImage: true,
                      saveAsScene: true,
                      saveAs: true,
                      loadScene: true,
                      undo: true,
                      redo: true,
                      clearCanvas: true,
                      toggleTheme: true,
                      toggleStats: false,
                      toggleLibrary: false, // Hide the library button
                    },
                  }}
                  initialData={{
                    appState: {
                      currentItemFontFamily: 2,
                      currentItemFontSize: 25,
                      theme: 'dark', // Set dark theme as default
                      contextMenu: {
                        items: [],
                        top: 0,
                        left: 0,
                      },
                    },
                  }}
                  excalidrawAPI={(api) => setExcalidrawAPI(api)}
                >
                  <MainMenu>
                    <MainMenu.DefaultItems.LoadScene />
                    {isPremium && <MainMenu.DefaultItems.SaveAsImage />}
                    {isPremium && <MainMenu.DefaultItems.Export />}
                    <MainMenu.Separator />
                    <MainMenu.DefaultItems.ClearCanvas />
                    <MainMenu.DefaultItems.ToggleTheme />

                    <MainMenu.DefaultItems.ChangeCanvasBackground />

                    <MainMenu.Separator />
                    {/* <MainMenu.ItemCustom>
                      <button
                        style={{ height: '2rem' }}
                        onClick={() => window.alert('custom menu item')}
                      >
                        custom item
                      </button>
                    </MainMenu.ItemCustom> */}
                    {/* <MainMenu.DefaultItems.Help /> */}
                  </MainMenu>
                  <Footer>
                    <div style={{ width: '100%' }}>
                      <MessageInputBar
                        onSend={handleSend}
                        onPause={handlePause}
                        isProcessing={isProcessing}
                      />
                    </div>{' '}
                  </Footer>
                </Excalidraw>

                <NewDownloadModal
                  form={form}
                  visible={downloadVisible}
                  onCancel={() => setDownloadVisible(false)}
                  onCreate={handleDownloadCreate}
                  selectedDownloadOption={selectedDownloadOption}
                  setSelectedDownloadOption={setSelectedDownloadOption}
                />
              </div>
            </div>
            {/* <div id="menu1" className="tab-pane fade">
              <div class="scrollexp">
                <MDEditor
                  preview="preview"
                  hideToolbar={true}
                  textareaProps={{
                    placeholder: 'Please enter Markdown text',
                  }}
                  height={700}
                  enableScroll={true}
                  value={allvalue}
                  previewOptions={{
                    components: {
                      code: DummyCode,
                    },
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="tab-footer">
          <div className="row"></div>
        </div>
        <LoadingBar color="#FF2D60" ref={ref} />
      </div>
      {/* <div className="banner-bottom-search">
        <div className="container-fluid">
          <div
            className={`banner-bottom-search-inner ${
              !submited ? 'banner-bottom-search-inner-position' : ''
            }`}
          >
            {' '}
            <div className="dashboardbtn dashboardbtn-mainsec">
              <div className="row">
                <LoadingBar color="#FF2D60" ref={ref} />
                <div className="col-sm-7 col-md-8 col-lg-9">
                  <div className="animation-btn inputsec-search">
                    <span className="button-value-desktop rainbow bottom-serch-cont">
                      <div className="gzPeFs">
                        {showFileUpload && (
                          <Dropzone
                            style={{ height: 100 }}
                            onChange={updateFiles}
                            value={files}
                            footer={false}
                            maxFiles={1}
                            label="Drop your files here to generate flowchart"
                            accept="image/*,application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          >
                            {files.map((file) => (
                              <FileMosaic
                                preview={true}
                                key={file.id}
                                {...file}
                                onDelete={removeFile}
                                info
                              />
                            ))}
                          </Dropzone>
                        )}
                        {!showFileUpload && (
                          <textarea
                            placeholder="Please provide your query here (e.g., 'Exploration of Android Architecture') or  paste  blog content."
                            defaultValue={''}
                            rows="4"
                            value={textareaValue}
                            onChange={(e) => setTextareaValue(e.target.value)}
                          />
                        )}
                      </div>
                    </span>
                    <div style={buttonGroupStyle}>
                      <TagButton
                        label={BUTTON_TEXT.BUTTON_1} // Use the constant for the button text
                        isSelected={selectedTagButton === BUTTON_TEXT.BUTTON_1}
                        onClick={() => handleButtonClick(BUTTON_TEXT.BUTTON_1)}
                      />
                      <TagButton
                        label={BUTTON_TEXT.BUTTON_2} // Use the constant for the button text
                        isSelected={selectedTagButton === BUTTON_TEXT.BUTTON_2}
                        onClick={() => handleButtonClick(BUTTON_TEXT.BUTTON_2)}
                      />
                      <TagButton
                        label={BUTTON_TEXT.BUTTON_3} // Use the constant for the button text
                        isSelected={selectedTagButton === BUTTON_TEXT.BUTTON_3}
                        onClick={() => handleButtonClick(BUTTON_TEXT.BUTTON_3)}
                      />
                      <TagButton
                        label={BUTTON_TEXT.BUTTON_4} // Use the constant for the button text
                        isSelected={selectedTagButton === BUTTON_TEXT.BUTTON_4}
                        onClick={() => handleButtonClick(BUTTON_TEXT.BUTTON_4)}
                      />
                      <TagButton
                        label={BUTTON_TEXT.BUTTON_5} // Use the constant for the button text
                        isSelected={selectedTagButton === BUTTON_TEXT.BUTTON_5}
                        onClick={() => handleButtonClick(BUTTON_TEXT.BUTTON_5)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 col-md-4 col-lg-3 center">
                  <div>
                    <a
                      onClick={handleSubmit}
                      href=""
                      className="animation-btn animated-ui start-with-ai text-area-btn"
                    >
                      <span className="button-value-desktop rainbow">
                        Generate AI diagram
                      </span>
                    </a>
                    <a
                      onClick={handleFileUpload}
                      href=""
                      className="animation-btn start-with-ai text-area-btn"
                    >
                      <span className="button-value-desktop rainbow">
                        Upload a file
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default AppView
