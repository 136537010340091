import axios from 'axios'
import APIKit from '../APIKit'
import { header, multipartheader } from '../header'

function CommonService(api, payload) {
  //console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))
  return new Promise(async (resolve, reject) => {
    try {
      const userJSON = localStorage.getItem('user')
      // console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userEmail = userObject.email

      //console.log('User email:', userEmail)

      // Add userEmail to payload
      payload.email = userEmail

      // Add userEmail to payload
      payload.token = window.localStorage.getItem('token')
    } catch (e) {
      //console.log('failed===========' + e)
    }

    APIKit.post(api, payload, header)
      .then(async (response) => {
        try {
          //   console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          //  console.log(JSON.stringify(response.data.status))
          // console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          // console.log('err===========', e)

          reject(e)
        }
      })
      .catch((err) => {
        console.log('err===========', err)

        reject(err)
      })
  })
}
function userdata(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const userJSON = localStorage.getItem('user')
      // console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userEmail = userObject.email

      // console.log('User email:', userEmail)

      payload.append('token', window.localStorage.getItem('token'))
      payload.append('email', userEmail)
    } catch (e) {
      //console.log('failed===========' + e)
    }
    //console.log('User payload:', payload)

    APIKit.post('userdata', payload, header)
      .then(async (response) => {
        try {
          // console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          //  console.log(JSON.stringify(response.data.status))
          // console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          // console.log('err===========', e)

          reject(e)
        }
      })
      .catch((err) => {
        // console.log('err===========', err)

        reject(err)
      })
  })
}

function diagram(api, payload) {
  //console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))
  return new Promise(async (resolve, reject) => {
    try {
      const userJSON = localStorage.getItem('user')
      //console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userEmail = userObject.email

      //console.log('User email:', userEmail)

      payload.append('token', window.localStorage.getItem('token'))
      payload.append('email', userEmail)
    } catch (e) {
      // console.log('failed===========' + e)
    }

    APIKit.post(api, payload, multipartheader)
      .then(async (response) => {
        try {
          //  console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          //  console.log(JSON.stringify(response.data.status))
          //  console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          // console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        // console.log('err===========' + err)

        reject(err)
      })
  })
}

function loginService(payload) {
  console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))

  return new Promise(async (resolve, reject) => {
    APIKit.post('login', payload, header)
      .then(async (response) => {
        try {
          // console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          // console.log(JSON.stringify(response.data.status))
          // console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          // console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        // console.log('err===========' + err)

        reject(err)
      })
  })
}
function registerService(payload) {
  console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))

  return new Promise(async (resolve, reject) => {
    APIKit.post('register', payload, header)
      .then(async (response) => {
        try {
          // console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          // console.log(JSON.stringify(response.data.status))
          // console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          // console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        //  console.log('err===========' + err)

        reject(err)
      })
  })
}

function apiService(api, payload) {
  // console.log('payload======send_otp_mobile=====' + JSON.stringify(payload))

  return new Promise(async (resolve, reject) => {
    APIKit.post(api, payload, header)
      .then(async (response) => {
        try {
          // console.log('response===========' + response.status)
          //console.log("response==========="+response.data.password)
          // console.log(JSON.stringify(response.data.status))
          // console.log('response======data=====' + response.data.success)

          // await setAuthAsyncStorage(response);
          resolve(response.data)
        } catch (e) {
          // console.log('err===========' + e)

          reject(e)
        }
      })
      .catch((err) => {
        // console.log('err===========' + err)

        reject(err)
      })
  })
}

export const commonService = {
  CommonService,
  loginService,
  registerService,
  apiService,
  diagram,
  userdata,
}
