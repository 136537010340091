import React from 'react'
import { useState, useEffect } from 'react'
import SuccessAnimation from 'actually-accessible-react-success-animation'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
const PaymentSuccess = () => {
  const history = useHistory()

  useEffect(() => {
    // Access the callback data from the window object
    const checkoutData = window.checkoutData

    // Use the checkoutData as needed in your component
    if (checkoutData) {
      console.log('Checkout Data:', checkoutData)
    }
  }, [])
  useEffect(() => {
    // Send the conversion event to Google Analytics
    ReactGA.event({
      category: 'Conversion',
      action: 'Purchase',
      label: 'YOUR-LABEL', // Optional: Add a label if needed
      value: 1, // Optional: Add a value if needed
      send_to: 'AW-10810690229/NeO_CJvLpvYYELWV-KIo', // Replace with your actual send_to value
    })
  }, [])
  const processSuccess = async () => {
    history.push('/')
  }

  return (
    <div
      style={{
        display: 'flex',
        WebkitBoxPack: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      }}
    >
      <section
        style={{
          background:
            'radial-gradient(at left top, rgba(246, 73, 66, 0.1), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box, radial-gradient(at right center, rgba(250, 219, 95, 0.1), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box, rgba(0, 0, 0, 0) radial-gradient(at right bottom, rgba(0, 148, 155, 0.15), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box',
          boxSizing: 'border-box',
          border: '0px solid rgb(229, 231, 235)',
          width: '45%',
          padding: '32px 28px',
          minHeight: '300px',
          WebkitBoxAlign: 'center',
          boxShadow:
            'rgba(255, 255, 255, 0.15) 0px 1px 3px 0px inset, rgb(33, 40, 49) 0px -1px 3px 0px inset',
          borderRadius: '24px',
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            border: '0px solid rgb(229, 231, 235)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              boxSizing: 'border-box',
              border: '0px solid rgb(229, 231, 235)',
              marginBottom: '4px',
            }}
          >
            <SuccessAnimation
              text="Thankyou for your purchase!"
              color="#0CE01B"
            />
          </div>
          <button
            onClick={processSuccess}
            style={{
              boxSizing: 'border-box',
              cursor: 'pointer',
              appearance: 'button',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              backgroundImage: 'none',
              textTransform: 'none',
              fontFamily:
                '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '27.52px',
              color: 'rgb(250, 250, 250)',
              margin: '0px',
              padding: '0px',
              border: '0px none rgb(250, 250, 250)',
              overflow: 'visible',
              display: 'flex',
              width: '100%',
              position: 'relative',
              outline: 'rgb(250, 250, 250) none 0px',
            }}
          >
            <div
              style={{
                maxWidth: '100%',
                cursor: 'pointer',
                borderWidth: '0px',
                borderRadius: '8px',
                backgroundColor: 'rgb(255, 45, 96)',
                color: 'rgb(250, 250, 250)',
                boxSizing: 'border-box',
                padding: '9.6px 24px',
                transition:
                  'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s',
                margin: '0px',
                fontFamily:
                  '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                fontSize: '16px',
                border: '0px none rgb(250, 250, 250)',
                fontWeight: 600,
                display: 'flex',
                width: '100%',
                placeContent: 'stretch center',
                WebkitBoxPack: 'center',
                WebkitBoxAlign: 'center',
                alignItems: 'center',
                height: '56px',
              }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  border: '0px solid rgb(229, 231, 235)',
                  opacity: 1,
                  transition: 'opacity 0.15s ease-in-out 0s',
                  margin: '0px',
                }}
              >
                Start Explore
              </span>
            </div>
          </button>
        </div>
      </section>
    </div>
  )
}

export default PaymentSuccess
