import React from 'react'
import { useState, useEffect } from 'react'

function Terms() {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        border: '0px solid rgb(229, 231, 235)',
      }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          border: '0px solid rgb(229, 231, 235)',
          display: 'flex',
          WebkitBoxPack: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid rgba(255, 255, 255, 0)',
            boxShadow:
              'rgba(255, 255, 255, 0) 0px 1px 1px 0px inset, rgba(0, 0, 0, 0) 0px -1px 3px 0px inset',
            backdropFilter: 'blur(24px)',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            width: '100%',
            transition: 'border-color 0.2s ease-in-out 0s',
            background:
              'rgb(0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box',
            position: 'sticky',
            zIndex: 11,
            top: '-1px',
          }}
        ></div>
        <div
          style={{
            boxSizing: 'border-box',
            border: '0px solid rgb(229, 231, 235)',
            display: 'grid',
            gridTemplateColumns: 'auto',
            padding: '40px 0px 80px',
            width: '100%',
            maxWidth: '960px',
            margin: '0px auto',
          }}
        >
          <div
            style={{
              paddingLeft: '10px',
              boxSizing: 'border-box',
              border: '0px solid rgb(229, 231, 235)',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                border: '0px solid rgb(229, 231, 235)',
                display: 'flex',
                marginBottom: '30px',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <h1
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 40px',
                  fontSize: '52px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '65px',
                  marginBottom: '40px',
                  color: '#fff',
                }}
              >
                <strong>Terms and Conditions</strong>
              </h1>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                Welcome to aiflowchart.io!
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                These terms and conditions outline the rules and regulations for
                the use of aiflowchart's Website, located at www.aiflowchart.io.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use aiflowchart.io if you do not
                agree to take all of the terms and conditions stated on this
                page.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company's terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client's needs in respect of
                provision of the Company's stated services, in accordance with
                and subject to, prevailing law of in. Any use of the above
                terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </p>

              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Cookies</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We employ the use of cookies. By accessing aiflowchart.io, you
                agreed to use cookies in agreement with the aiflowchart's
                Privacy Policy.{' '}
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>

              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>License</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                Unless otherwise stated, aiflowchart and/or its licensors own
                the intellectual property rights for all material on
                aiflowchart.io. All intellectual property rights are reserved.
                You may access this from aiflowchart.io for your own personal
                use subjected to restrictions set in these terms and conditions.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                You must not:
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Republish material from aiflowchart.io{' '}
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Sell, rent or sub-license material from aiflowchart.io{' '}
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Reproduce, duplicate or copy material from aiflowchart.io
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Redistribute content from aiflowchart.io{' '}
                  </p>
                </li>
              </ul>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. aiflowchart does not filter, edit, publish or review
                Comments prior to their presence on the website. Comments do not
                reflect the views and opinions of aiflowchart,its agents and/or
                affiliates. Comments reflect the views and opinions of the
                person who post their views and opinions. To the extent
                permitted by applicable laws, aiflowchart shall not be liable
                for the Comments or for any liability, damages or expenses
                caused and/or suffered as a result of any use of and/or posting
                of and/or appearance of the Comments on this website.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                aiflowchart reserves the right to monitor all Comments and to
                remove any Comments which can be considered inappropriate,
                offensive or causes breach of these Terms and Conditions.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                You warrant and represent that:
              </p>

              <ul>
                <li>
                  {' '}
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    You are entitled to post the Comments on our website and
                    have all necessary licenses and consents to do so;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    The Comments do not invade any intellectual property right,
                    including without limitation copyright, patent or trademark
                    of any third party;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    The Comments do not contain any defamatory, libelous,
                    offensive, indecent or otherwise unlawful material which is
                    an invasion of privacy
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    The Comments will not be used to solicit or promote business
                    or custom or present commercial activities or unlawful
                    activity.
                  </p>
                </li>
              </ul>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                You hereby grant aiflowchart a non-exclusive license to use,
                reproduce, edit and authorize others to use, reproduce and edit
                any of your Comments in any and all forms, formats or media.
              </p>

              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Hyperlinking to our Content</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                The following organizations may link to our Website without
                prior written approval:
              </p>

              <ul>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Government agencies;{' '}
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Search engines;{' '}
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    News organizations;{' '}
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    Online directory distributors may link to our Website in the
                    same manner as they hyperlink to the Websites of other
                    listed businesses; and
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    System wide Accredited Businesses except soliciting
                    non-profit organizations, charity shopping malls, and
                    charity fundraising groups which may not hyperlink to our
                    Web site.
                  </p>
                </li>
              </ul>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                These organizations may link to our home page, to publications
                or to other Website information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party's site.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We may consider and approve other link requests from the
                following types of organizations:
              </p>

              <ul>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    commonly-known consumer and/or business information sources;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    dot.com community sites;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    associations or other groups representing charities;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    online directory distributors;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    internet portals;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    accounting, law and consulting firms; and
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    educational institutions and trade associations.
                  </p>
                </li>
              </ul>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of aiflowchart; and (d) the link is in the context of general
                resource information.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party's site.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to aiflowchart. Please include your name,
                your organization name, contact information as well as the URL
                of your site, a list of any URLs from which you intend to link
                to our Website, and a list of the URLs on our site to which you
                would like to link. Wait 2-3 weeks for a response.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                Approved organizations may hyperlink to our Website as follows:
              </p>

              <ul>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    By use of our name; or{' '}
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    By use of the uniform resource locator being linked to; or
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    By use of any other description of our Website being linked
                    to that makes sense within the context and format of content
                    on the linking party's site.
                  </p>
                </li>
              </ul>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                No use of aiflowchart's logo or other artwork will be allowed
                for linking absent a trademark license agreement.
              </p>

              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>iFrames</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </p>

              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Content Liability</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </p>

              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Reservation of Rights</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amen these terms and conditions and it's linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </p>

              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Removal of links from our website</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>
              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Dependency on Third-Party Services</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                aiflowchart.io relies on the functionality and responses
                provided by the ChatGPT API, which is a third-party service.
                While we make every effort to ensure the smooth operation of our
                service, we cannot guarantee uninterrupted access or performance
                due to factors beyond our control, including changes in the
                ChatGPT API's policies, updates to its technology, or unexpected
                responses.
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We will endeavor to adapt to any changes in the ChatGPT API to
                minimize disruption to our service. However, users acknowledge
                and accept that variations in the performance or behavior of the
                ChatGPT API may impact the functionality and results of
                aiflowchart.io.{' '}
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We encourage users to review ChatGPT's terms of service and
                privacy policy for additional information on their service and
                any potential implications for aiflowchart.io.{' '}
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                By using aiflowchart.io, users agree to accept the inherent
                risks associated with dependency on third-party services and
                acknowledge that aiflowchart.io's performance may be affected by
                changes to the ChatGPT API or other external factors beyond our
                control.{' '}
              </p>
              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Refund Policy</strong>
              </h2>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                All purchases of our plans and services on aiflowchart.io are
                non-refundable.
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                Once you have made a purchase and the payment has been
                processed, no refunds will be provided, regardless of the reason
                for cancellation or termination of your account or subscription.{' '}
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We may, at our sole discretion, consider refund requests in
                exceptional circumstances, but such requests are not guaranteed
                to be granted.{' '}
              </p>
              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Service Interruptions and Closure</strong>
              </h2>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                In the event of unforeseen circumstances, including but not
                limited to hardware failure, natural disasters, or personal
                issues affecting the operation of aiflowchart.io, we reserve the
                right to temporarily suspend or permanently close the service
                without prior notice.
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We will make reasonable efforts to inform users of any service
                interruptions or closures through available channels, such as
                email notifications or announcements on the website. However, we
                cannot guarantee continuous availability of the service under
                such circumstances.
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                In the event of permanent closure, users will not be entitled to
                refunds for any unused portion of their subscription or account
                balances. We will not be liable for any losses or damages
                resulting from the interruption or closure of the service.{' '}
              </p>
              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                We appreciate your understanding and patience in such
                situations, and we will strive to resolve any issues and provide
                assistance to the best of our abilities.{' '}
              </p>
              <h2
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 20px',
                  fontSize: '21px',
                  fontWeight: 700,
                  border: '0px solid rgb(229, 231, 235)',
                  lineHeight: '26.25px',
                  marginBottom: '20px',
                  color: '#fff',
                }}
              >
                <strong>Disclaimer</strong>
              </h2>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>

              <ul>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    limit or exclude our or your liability for death or personal
                    injury;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation;
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    limit any of our or your liabilities in any way that is not
                    permitted under applicable law; or
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      boxSizing: 'border-box',
                      margin: '0px 0px 24px',
                      border: '0px solid rgb(229, 231, 235)',
                      color: 'rgb(240, 240, 240)',
                      marginBottom: '24px',
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    exclude any of our or your liabilities that may not be
                    excluded under applicable law.
                  </p>
                </li>
              </ul>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </p>

              <p
                style={{
                  boxSizing: 'border-box',
                  margin: '0px 0px 24px',
                  border: '0px solid rgb(229, 231, 235)',
                  color: 'rgb(240, 240, 240)',
                  marginBottom: '24px',
                  fontWeight: 500,
                  fontSize: '16px',
                  letterSpacing: '0.32px',
                }}
              >
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            boxSizing: 'border-box',
            border: '0px solid rgb(229, 231, 235)',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background:
              'rgb(0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              boxSizing: 'border-box',
              border: '0px solid rgb(229, 231, 235)',
              display: 'flex',
              width: '100%',
              borderTop: '1px solid rgb(71, 71, 71)',
              WebkitBoxAlign: 'center',
              alignItems: 'center',
              WebkitBoxPack: 'center',
              justifyContent: 'center',
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Terms
