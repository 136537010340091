export const header = {
  headers: {
    'x-api-key': 'GgPWNQU9iCa5L5bewJr3t4cSchrktYWW8DT4FDEi',
  },
}

export const multipartheader = {
  headers: {
    'x-api-key': 'GgPWNQU9iCa5L5bewJr3t4cSchrktYWW8DT4FDEi',
    'content-type': 'multipart/form-data',
  },
}
