import React from 'react'
import AppView from './AppView'
import AppViewEx from './AppViewEx'

import LandingPage from './LandingPage'
import { useState, useEffect } from 'react'

function Home() {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedValue = localStorage.getItem('isLoggedIn')

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      setLoggedIn(true)
    }
  }, [])
  return (
    <div>
      {loggedIn === true && <AppViewEx />}
      {loggedIn !== true && <LandingPage />}
    </div>
  )
}

export default Home
