import React, { useState, useEffect } from 'react'
import { Modal, Form } from 'antd'
import 'antd/dist/reset.css'
import { RgbaStringColorPicker } from 'react-colorful'
import Select from 'react-select'

const NewDownloadModal = ({
  form,
  visible,
  onCancel,
  onCreate,
  selectedDownloadOption,
  setSelectedDownloadOption,
}) => {
  //const [color, setColor] = useState("#aabbcc");
  const fileTypes = [
    { value: 'png', label: 'PNG' },
    { value: 'jpeg', label: 'JPEG' },
  ]
  const [modalColor, setModalColor] = useState('') // Local state to manage the color inside the modal

  const handleCreate = () => {
    try {
      form.validateFields().then((values) => {
        console.log('Received values of form:', values)
        form.resetFields()
        onCreate(values)
        onCancel()
      })
    } catch (error) {
      console.error('Error while downloading the image:', error)
    }
  }

  return (
    <Modal
      visible={visible}
      title="Download Option"
      okText="Download"
      onCancel={onCancel}
      onOk={handleCreate}
      // style={{
      //   backgroundColor: '#222',
      //   color: '#fff',
      // }}
      // bodyStyle={{
      //   backgroundColor: '#333',
      // }}
      // headerStyle={{
      //   backgroundColor: '#111',
      //   color: '#fff',
      // }}
      // footerStyle={{
      //   backgroundColor: '#111',
      // }}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="" name="fileType">
          <Select
            options={fileTypes}
            autoFocus
            value={
              selectedDownloadOption
                ? {
                    value: selectedDownloadOption.value,
                    label: selectedDownloadOption.label,
                  }
                : { value: 'png', label: 'PNG' }
            }
            placeholder="PNG"
            onChange={(option) => setSelectedDownloadOption(option)}
          />
        </Form.Item>
        To access more download options, click the hamburger menu and select
        'Export image'.
      </Form>
    </Modal>
  )
}

export default NewDownloadModal
