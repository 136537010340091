import React, { useCallback } from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import App from './App'
import Login from './views/Login'

import { BrowserRouter as Router } from 'react-router-dom'
import Mermaid from './Mermaid'
import example from './example'
import './styles.css'
import axios from 'axios'
import MermaidDiagram from './MermaidDiagram'
import MermaidEditor from './MermaidEditor'
const baseURL = 'https://jsonplaceholder.typicode.com/posts'
const apiKey = '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' // Replace with your actual API key
const apiEndpoint = 'https://api.openai.com/v1/chat/completions'

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${apiKey}`,
}

// export default function App() {
//   const [post, setPost] = React.useState("");
//   const [mermaidCode, setMermaidCode] = React.useState("");
//   const mermaidContent = `
//   graph TD
//   A(Android)-->B(Activity)
//   A-->C(Fragment)
//   A-->D(Service)
//   A-->E(Broadcast Receiver)
//   A-->F(Content Provider)
//   B-->G(View Model)
//   B-->H(Lifecycle Observer)
//   C-->G
//   C-->H
//   D-->H
//   E-->H
//   F-->H
//   `;
//   const data = {
//     model: 'gpt-3.5-turbo',
//     "messages": [{"role": "system", "content": "write mdMermaid code with explanation"},
//     {"role": "user", "content": post}],
//   };
//   React.useEffect(() => {
//     axios.get(`${baseURL}/1`).then((response) => {
//       setPost(response.data);
//     });
//   }, []);

//   function handlePost() {
//     axios
//     .post(apiEndpoint, data, { headers })
//     .then((response) => {
//       // Handle the response from the API
//       const chatGptResponse = response.data.choices[0].message.content;
//       console.log('ChatGPT response:', chatGptResponse.toString());
//       setMermaidCode(chatGptResponse.toString());
//     })
//     .catch((error) => {
//       // Handle errors
//       console.error('Error interacting with ChatGPT API:', error);
//     });
//   }

//   const handleMermaidError = useCallback((error) => {
//     console.error('Error rendering Mermaid diagram:', error);
//     // You can handle the error here, e.g., show an error message or fallback UI.
//   }, []);

//   function extractMermaidCode(content) {
//     const regex = /```mermaid([\s\S]*?)```/gm;
//         const match = regex.exec(content);
//     if (match) {
//       console.log('extracted mermaid', match[1]);
//       return match[1].trim();
//         } else {
//       console.log('extracted mermaid error');
//       return content;
//     }
//   }

//   return (
//   //   <div>
//   //   <div className="big-input-post">
//   //   <textarea
//   //     onChange={(e) => setPost(e.target.value)}
//   //     rows={5}
//   //     placeholder="Write your prompt here..."
//   //   />
//   //   <button onClick={handlePost}>Post</button>
//   // </div>
//   // <div className="diagram_area" >
//   // <MermaidEditor content={mermaidCode}/>

//   // </div>
//   // </div>

//   );
// }
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" component={App} />
    </Switch>
  </BrowserRouter>,

  document.getElementById('root'),
)
