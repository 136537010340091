import React, { useState,useEffect } from "react";
import { Modal, Form } from "antd";
import "antd/dist/reset.css";
import { RgbaStringColorPicker } from "react-colorful";

const ColorPickerModal = ({ form, visible,color, onCancel, onCreate }) => {
  //const [color, setColor] = useState("#aabbcc");
  const [modalColor, setModalColor] = useState(color); // Local state to manage the color inside the modal
  console.log("colorrrrrrr"+color)

  useEffect(() => {
    setModalColor(color); // Update the modal's color state when the prop changes
  }, [color]);
  const handleCreate = () => {
    form.validateFields().then((values) => {
      console.log("Received values of form:", values);
      form.resetFields();
      onCreate(values);
      onCancel();
    });
  };

  return (
    <Modal
      visible={visible}
      title="Pick a Color"
      okText="Submit"
      onCancel={onCancel}
      onOk={handleCreate}
  // Set the background color of the modal
    >
      <Form form={form} layout="vertical"  style={{ backgroundColor: modalColor }}>
        <Form.Item name="color" label="">
          <RgbaStringColorPicker color={modalColor} onChange={setModalColor}  />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ColorPickerModal;
