import React, { useEffect,useRef } from 'react';
import mermaid from "mermaid";

function extractMermaidCode(content) {
    const cleanContent = content.replace(/mermaid/gi, '');
    console.log('cleanContent',cleanContent);
    //const regex = /```([\s\S]*?)```|(Note:[\s\S]*)/gm;
    const regex = /```([\s\S]*?)```/gm;
        const match = regex.exec(cleanContent);
    if (match) {
      console.log('extracted mermaid', match[1]);
      return match[1];
        } else {
      console.log('extracted mermaid error');
      return cleanContent;
    }
  }
  const config = {
    theme: 'dark',
    logLevel: 'fatal',
    securityLevel: 'strict',
    startOnLoad: true,
    arrowMarkerAbsolute: false,
  
    er: {
      diagramPadding: 20,
      layoutDirection: 'TB',
      minEntityWidth: 100,
      minEntityHeight: 75,
      entityPadding: 15,
      stroke: 'gray',
      fill: 'honeydew',
      fontSize: 12,
      useMaxWidth: true,
    },
    flowchart: {
      diagramPadding: 8,
      htmlLabels: true,
      curve: 'basis',
    },
    sequence: {
      diagramMarginX: 50,
      diagramMarginY: 10,
      actorMargin: 50,
      width: 150,
      height: 65,
      boxMargin: 10,
      boxTextMargin: 5,
      noteMargin: 10,
      messageMargin: 35,
      messageAlign: 'center',
      mirrorActors: true,
      bottomMarginAdj: 1,
      useMaxWidth: true,
      rightAngles: false,
      showSequenceNumbers: false,
    },
    gantt: {
      titleTopMargin: 25,
      barHeight: 20,
      barGap: 4,
      topPadding: 50,
      leftPadding: 75,
      gridLineStartPadding: 35,
      fontSize: 11,
      fontFamily: '"Open Sans", sans-serif',
      numberSectionStyles: 4,
      axisFormat: '%Y-%m-%d',
      topAxis: false,
      displayMode: '',
      
    },
    graph: {
      titleTopMargin: 25,
      barHeight: 20,
      barGap: 4,
      topPadding: 50,
      leftPadding: 75,
      gridLineStartPadding: 35,
      fontSize: 11,
      fontFamily: '"Open Sans", sans-serif',
      numberSectionStyles: 4,
      axisFormat: '%Y-%m-%d',
      topAxis: false,
      displayMode: '',
      
    },
  };
  mermaid.initialize(config);

  export const Mermaid = ({ chart }) => {
    const containerRef = useRef();
  
    useEffect(() => {
      mermaid.contentLoaded();
    }, []);
  
    useEffect(() => {
      if (containerRef.current) {
        containerRef.current.removeAttribute('data-processed');
        containerRef.current.innerHTML = chart;
        mermaid.contentLoaded();
      }
    }, [chart]);
    const handleMouseScroll = (event) => {
        if (event.deltaY > 0) {
          // Scrolling down, zoom out
          if (containerRef.current) {
            containerRef.current.style.zoom = parseFloat(containerRef.current.style.zoom) - 0.1;
          }
        } else if (event.deltaY < 0) {
          // Scrolling up, zoom in
          if (containerRef.current) {
            containerRef.current.style.zoom = parseFloat(containerRef.current.style.zoom) + 0.1;
          }
        }
      };
    
    return (
        <div onWheel={handleMouseScroll} style={{ overflow: 'hidden' }}>
      <div id="mermaid-chart" className="mermaid" ref={containerRef}>
        {chart}
      </div>
      </div>
    );
  };
  
const MermaidDiagram = ({ content }) => {
  const mermaidCode = extractMermaidCode(content);
  console.log('mermaidCode',mermaidCode.toString());
  let chart =``+mermaidCode+``;
  useEffect(() => {
   
        mermaid.contentLoaded();

    // Clean up the mermaid instance when the component unmounts
    return () => {
     // mermaid.clean();
    };
  }, []);

  return (
    <div >
      {chart ? (
        <div> <Mermaid chart={chart} /></div>
      ) : (
        <div>Alternative View when Chart is Null</div>
      )}
    </div>
  );
};

export default MermaidDiagram;
