import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PromoBanner from './PromoBanner'
function Header() {
  const history = useHistory()

  const [loggedIn, setLoggedIn] = useState(false)
  const [name, setName] = useState('User')

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedValue = localStorage.getItem('isLoggedIn')
    try {
      const userJSON = localStorage.getItem('user')
      // console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)
      if (window.setClarityUserEmail) {
        window.setClarityUserEmail(userObject.email)
      }
      // Access the email property
      const userName = userObject.name
      setName(userName)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      setLoggedIn(true)
    }
  }, [])
  const onLogout = () => {
    window.localStorage.setItem('isLoggedIn', false)
    window.localStorage.setItem('token', '')
    window.localStorage.setItem('user', null)
    setLoggedIn(false)
    history.push('/')

    window.location.reload()
  }
  const pricing = (e) => {
    e.preventDefault()
    history.push('/pricing')
  }
  const signin = (e) => {
    e.preventDefault()
    history.push('/login')
  }
  const signup = (e) => {
    e.preventDefault()
    history.push('/register')
  }
  return (
    <div>
      <PromoBanner />
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle" />
          </div>
        </div>
        <div className="site-mobile-menu-body" />
      </div>
      {/* .site-mobile-menu */}
      <div className="site-navbar site-navbar-target js-sticky-header">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-10 col-sm-10 col-md-3 col-lg-2">
              <h1 className="my-0 site-logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo_transperent.png')}
                    width="55"
                    height="50"
                  />
                </a>
              </h1>
            </div>
            <div className="col-2 col-sm-2 col-md-3 col-lg-5">
              <nav className="site-navigation " role="navigation">
                <div className="container-fluid1">
                  <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                    <a
                      href="#"
                      className="site-menu-toggle js-menu-toggle text-white"
                    >
                      <span className="icon-menu h3" />
                    </a>
                  </div>
                  <ul className="site-menu main-menu js-clone-nav d-none d-lg-block">
                    <li>
                      <a href="/pricing" className="nav-link">
                        pricing
                      </a>
                    </li>
                    <li className="has-children">
                      <a href="#" className="nav-link">
                        Links
                      </a>
                      <ul className="dropdown arrow-top">
                        <li>
                          <a
                            href="https://resumeanalyser.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Resume Analyser
                          </a>
                        </li>
                        <li>
                          <a href="/legacy" className="nav-link">
                            Legacy App
                          </a>
                        </li>
                        <li>
                          <a href="/newversion" className="nav-link">
                            aiflowchart 2.0
                          </a>
                        </li>
                        <li>
                          <a href="/terms" className="nav-link">
                            Terms and Conditions
                          </a>
                        </li>
                        <li>
                          <a href="/privacy" className="nav-link">
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a href="/contact" className="nav-link">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 websitedashboard">
              {loggedIn === true && (
                <nav className="site-navigation " role="navigation">
                  <div className="container-fluid1">
                    <ul className="site-menu main-menu js-clone-nav d-none d-lg-block">
                      {/* <li>
                        <a href="/register" className="nav-link">
                          Solutions
                        </a>
                      </li> */}
                      <li className="has-children">
                        <a href="#" className="nav-link">
                          {name}
                        </a>
                        <ul className="dropdown arrow-top">
                          <li>
                            <a href="/profile" className="nav-link">
                              My Account
                            </a>
                          </li>
                          <li>
                            <a href="#" onClick={onLogout} className="nav-link">
                              Logout
                            </a>
                          </li>
                          {/* <li>
                            <a href="#" className="nav-link">
                              FAQ
                            </a>
                          </li> */}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
              )}
              {loggedIn !== true && (
                <div className="dashboardbtn dashboardbtn-mainsec">
                  <a href="" onClick={signin} className="contactsalebtn">
                    Login
                  </a>
                  <a
                    href=""
                    onClick={signup}
                    className="animation-btn animated-ui"
                  >
                    <span className="button-value-desktop rainbow">
                      Sign up for free
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
