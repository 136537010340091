import { useHistory } from 'react-router-dom'

const PaymentCancelled = () => {
  const history = useHistory()

  const navigateToPricing = async () => {
    history.push('/pricing')
  }

  return (
    <div
      style={{
        display: 'flex',
        WebkitBoxPack: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 200,
      }}
    >
      <section
        style={{
          boxSizing: 'border-box',
          border: '0px solid rgb(229, 231, 235)',
          width: '45%',
          padding: '32px 28px',
          minHeight: '300px',
          WebkitBoxAlign: 'center',
          boxShadow:
            'rgba(255, 255, 255, 0.15) 0px 1px 3px 0px inset, rgb(33, 40, 49) 0px -1px 3px 0px inset',
          borderRadius: '24px',
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            border: '0px solid rgb(229, 231, 235)',
            display: 'flex',
            justifyItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2
            style={{
              fontWeight: 500,
              lineHeight: '30.72px',
              fontSize: '30px',
              marginTop: '0px',
              marginBottom: '8px',
              color: 'rgb(250, 250, 250)',
              boxSizing: 'border-box',
              margin: '0px 0px 8px',
              border: '0px solid rgb(229, 231, 235)',
              alignSelf: 'center',
            }}
          >
            Payment Cancelled
          </h2>
          <div
            style={{
              boxSizing: 'border-box',
              border: '0px solid rgb(229, 231, 235)',
              marginBottom: '4px',
              marginTop: 100,
            }}
          ></div>
          <button
            style={{
              boxSizing: 'border-box',
              cursor: 'pointer',
              appearance: 'button',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              backgroundImage: 'none',
              textTransform: 'none',
              fontFamily:
                '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '27.52px',
              color: 'rgb(250, 250, 250)',
              margin: '0px',
              padding: '0px',
              border: '0px none rgb(250, 250, 250)',
              overflow: 'visible',
              display: 'flex',
              width: '100%',
              position: 'relative',
              outline: 'rgb(250, 250, 250) none 0px',
            }}
            onClick={navigateToPricing}
          >
            <div
              style={{
                maxWidth: '100%',
                cursor: 'pointer',
                borderWidth: '0px',
                borderRadius: '8px',
                backgroundColor: 'rgb(255, 45, 96)',
                color: 'rgb(250, 250, 250)',
                boxSizing: 'border-box',
                padding: '9.6px 24px',
                transition:
                  'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s',
                margin: '0px',
                fontFamily:
                  '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                fontSize: '16px',
                border: '0px none rgb(250, 250, 250)',
                fontWeight: 600,
                display: 'flex',
                width: '100%',
                placeContent: 'stretch center',
                WebkitBoxPack: 'center',
                WebkitBoxAlign: 'center',
                alignItems: 'center',
                height: '56px',
              }}
            >
              <span
                style={{
                  boxSizing: 'border-box',
                  border: '0px solid rgb(229, 231, 235)',
                  opacity: 1,
                  transition: 'opacity 0.15s ease-in-out 0s',
                  margin: '0px',
                }}
              >
                Try Again
              </span>
            </div>
          </button>
        </div>
      </section>
    </div>
  )
}

export default PaymentCancelled
