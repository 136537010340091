import React from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Profile = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isSocial, setIsSocial] = useState(false)
  const history = useHistory()
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const storedValue = localStorage.getItem('isLoggedIn')

    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      setName(userName)
      setEmail(userEmail)
      setIsSocial(userObject.isSocial)
    } catch (err) {}

    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      //setLoggedIn(true)
    }
  }, [])
  const onLogout = () => {
    window.localStorage.setItem('isLoggedIn', false)
    window.localStorage.setItem('token', '')
    window.localStorage.setItem('user', null)
    setLoggedIn(false)
    history.push('/')

    window.location.reload()
  }
  return (
    <div>
      <div
        style={{
          boxSizing: 'border-box',
          borderWidth: '0px',
          borderStyle: 'solid',
          borderColor: 'rgb(227, 229, 232)',
        }}
      >
        <div
          style={{
            position: 'relative',
            zIndex: 0,
            boxSizing: 'border-box',
            borderWidth: '0px',
            borderStyle: 'solid',
            borderColor: 'rgb(227, 229, 232)',
          }}
        >
          <div
            style={{
              boxSizing: 'border-box',
              borderWidth: '0px',
              borderStyle: 'solid',
              borderColor: 'rgb(227, 229, 232)',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                borderWidth: '0px',
                borderStyle: 'solid',
                borderColor: 'rgb(227, 229, 232)',
                display: 'flex',
                gap: '8px',
              }}
            >
              <div
                style={{
                  overflowX: 'visible',
                  boxSizing: 'border-box',
                  borderWidth: '0px',
                  borderStyle: 'solid',
                  borderColor: 'rgb(227, 229, 232)',
                  display: 'flex',
                  flex: '1 1 0%',
                }}
              >
                <div
                  style={{
                    display: 'none',
                    boxSizing: 'border-box',
                    borderWidth: '0px',
                    borderStyle: 'solid',
                    borderColor: 'rgb(227, 229, 232)',
                    position: 'fixed',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    zIndex: 6,
                    transition:
                      'background-color 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s, visibility 0s ease 0.3s',
                    visibility: 'hidden',
                    pointerEvents: 'none',
                  }}
                ></div>
                <div
                  style={{
                    width: '20.25rem',
                    transition:
                      'width 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s',
                    boxSizing: 'border-box',
                    borderWidth: '0px',
                    borderStyle: 'solid',
                    borderColor: 'rgb(227, 229, 232)',
                    display: 'flex',
                    WebkitBoxAlign: 'stretch',
                    alignItems: 'stretch',
                    position: 'sticky',
                    top: '0px',
                    height: '100%',
                    WebkitBoxPack: 'end',
                    justifyContent: 'flex-end',
                    zIndex: 1,
                    userSelect: 'none',
                    backdropFilter: 'blur(10px)',
                  }}
                >
                  <div
                    style={{
                      boxSizing: 'border-box',
                      borderWidth: '0px 1px 0px 0px',
                      borderStyle: 'solid',
                      borderColor:
                        'rgb(227, 229, 232) rgb(37, 40, 45) rgb(227, 229, 232) rgb(227, 229, 232)',
                      flexDirection: 'column',
                      width: '16rem',
                      overflow: 'hidden',
                      transition:
                        'width 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s',
                      display: 'flex',
                      flexShrink: 0,
                      borderRight: '1px solid rgb(37, 40, 45)',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        borderWidth: '0px',
                        borderStyle: 'solid',
                        borderColor: 'rgb(227, 229, 232)',
                        height: '100%',
                        minWidth: '256px',
                        padding: '10px',
                        overflowY: 'auto',
                      }}
                    >
                      <div
                        style={{
                          boxSizing: 'border-box',
                          borderWidth: '0px',
                          borderStyle: 'solid',
                          borderColor: 'rgb(227, 229, 232)',
                        }}
                      >
                        <a
                          aria-current="page"
                          href="/profile"
                          style={{
                            textDecoration: 'none solid rgb(227, 229, 232)',
                            color: 'rgb(227, 229, 232)',
                            boxSizing: 'border-box',
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            borderColor: 'rgb(227, 229, 232)',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              borderWidth: '0px',
                              borderStyle: 'solid',
                              borderColor: 'rgb(227, 229, 232)',
                              color: 'rgb(227, 229, 232)',
                              background:
                                'rgb(37, 40, 45) none repeat scroll 0% 0% / auto padding-box border-box',
                              display: 'flex',
                              width: '100%',
                              WebkitBoxAlign: 'center',
                              alignItems: 'center',
                              borderRadius: '10px',
                              padding: '4px 10px',
                              marginBottom: '2px',
                              position: 'relative',
                              fontSize: '14.222px',
                              lineHeight: '16.9px',
                            }}
                          >
                            <span
                              style={{
                                boxSizing: 'border-box',
                                borderWidth: '0px',
                                borderStyle: 'solid',
                                borderColor: 'rgb(227, 229, 232)',
                                width: '1.625rem',
                                height: '26px',
                                display: 'flex',
                                WebkitBoxAlign: 'center',
                                alignItems: 'center',
                                WebkitBoxPack: 'center',
                                justifyContent: 'center',
                                marginRight: '8px',
                                transform: 'matrix(0.9, 0, 0, 0.9, 0, 0)',
                              }}
                            >
                              <svg
                                fill="currentColor"
                                height="20"
                                stroke-width="0"
                                width="20"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  display: 'block',
                                  verticalAlign: 'middle',
                                  boxSizing: 'border-box',
                                  borderWidth: '0px',
                                  borderStyle: 'solid',
                                  borderColor: 'rgb(227, 229, 232)',
                                }}
                              >
                                <path
                                  d="M10.2501 3.7235C8.80865 3.7235 7.64016 4.89199 7.64016 6.33339C7.64016 7.77479 8.80865 8.94328 10.2501 8.94328C11.6915 8.94328 12.8599 7.77479 12.8599 6.33339C12.8599 4.89199 11.6915 3.7235 10.2501 3.7235ZM6.19328 6.33339C6.19328 4.09289 8.00956 2.27661 10.2501 2.27661C12.4906 2.27661 14.3068 4.09289 14.3068 6.33339C14.3068 8.57388 12.4906 10.3902 10.2501 10.3902C8.00956 10.3902 6.19328 8.57388 6.19328 6.33339ZM4.46481 13.4648C5.22561 12.704 6.25746 12.2766 7.33339 12.2766H13.1667C14.2426 12.2766 15.2745 12.704 16.0353 13.4648C16.7961 14.2256 17.2235 15.2575 17.2235 16.3334V18.0001C17.2235 18.3996 16.8996 18.7235 16.5001 18.7235C16.1005 18.7235 15.7766 18.3996 15.7766 18.0001V16.3334C15.7766 15.6412 15.5016 14.9774 15.0122 14.4879C14.5227 13.9985 13.8589 13.7235 13.1667 13.7235H7.33339C6.6412 13.7235 5.97737 13.9985 5.48792 14.4879C4.99847 14.9774 4.7235 15.6412 4.7235 16.3334V18.0001C4.7235 18.3996 4.3996 18.7235 4.00005 18.7235C3.60051 18.7235 3.27661 18.3996 3.27661 18.0001V16.3334C3.27661 15.2575 3.70402 14.2256 4.46481 13.4648Z"
                                  fill-rule="evenodd"
                                  style={{
                                    boxSizing: 'border-box',
                                    borderWidth: '0px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgb(227, 229, 232)',
                                  }}
                                ></path>
                              </svg>
                            </span>
                            General
                          </div>
                        </a>
                        <a
                          href="/billing"
                          style={{
                            textDecoration: 'none solid rgb(227, 229, 232)',
                            color: 'rgb(227, 229, 232)',
                            boxSizing: 'border-box',
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            borderColor: 'rgb(227, 229, 232)',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              borderWidth: '0px',
                              borderStyle: 'solid',
                              borderColor: 'rgb(199, 204, 209)',
                              display: 'flex',
                              width: '100%',
                              color: 'rgb(199, 204, 209)',
                              WebkitBoxAlign: 'center',
                              alignItems: 'center',
                              borderRadius: '10px',
                              padding: '4px 10px',
                              marginBottom: '2px',
                              position: 'relative',
                              fontSize: '14.222px',
                              lineHeight: '16.9px',
                            }}
                          >
                            <span
                              style={{
                                boxSizing: 'border-box',
                                borderWidth: '0px',
                                borderStyle: 'solid',
                                borderColor: 'rgb(199, 204, 209)',
                                width: '1.625rem',
                                height: '26px',
                                display: 'flex',
                                WebkitBoxAlign: 'center',
                                alignItems: 'center',
                                WebkitBoxPack: 'center',
                                justifyContent: 'center',
                                marginRight: '8px',
                                transform: 'matrix(0.9, 0, 0, 0.9, 0, 0)',
                              }}
                            >
                              <svg
                                fill="currentColor"
                                height="20"
                                stroke-width="0"
                                width="20"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  display: 'block',
                                  verticalAlign: 'middle',
                                  boxSizing: 'border-box',
                                  borderWidth: '0px',
                                  borderStyle: 'solid',
                                  borderColor: 'rgb(199, 204, 209)',
                                }}
                              >
                                <path
                                  d="M2.49997 4.05675C1.97905 4.05675 1.55675 4.47905 1.55675 4.99997V7.60986H18.4432V4.99997C18.4432 4.47905 18.0209 4.05675 17.5 4.05675H2.49997ZM19.8901 4.99997C19.8901 3.67995 18.82 2.60986 17.5 2.60986H2.49997C1.17995 2.60986 0.109863 3.67995 0.109863 4.99997V15C0.109863 16.32 1.17995 17.3901 2.49997 17.3901H17.5C18.82 17.3901 19.8901 16.32 19.8901 15V4.99997ZM18.4432 9.05675H1.55675V15C1.55675 15.5209 1.97905 15.9432 2.49997 15.9432H17.5C18.0209 15.9432 18.4432 15.5209 18.4432 15V9.05675Z"
                                  fill-rule="evenodd"
                                  style={{
                                    boxSizing: 'border-box',
                                    borderWidth: '0px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgb(199, 204, 209)',
                                  }}
                                ></path>
                              </svg>
                            </span>
                            Billing
                          </div>
                        </a>
                        {!isSocial && (
                          <a
                            href="/changepassword"
                            style={{
                              textDecoration: 'none solid rgb(227, 229, 232)',
                              color: 'rgb(227, 229, 232)',
                              boxSizing: 'border-box',
                              borderWidth: '0px',
                              borderStyle: 'solid',
                              borderColor: 'rgb(227, 229, 232)',
                            }}
                          >
                            <div
                              style={{
                                boxSizing: 'border-box',
                                borderWidth: '0px',
                                borderStyle: 'solid',
                                borderColor: 'rgb(199, 204, 209)',
                                display: 'flex',
                                width: '100%',
                                color: 'rgb(199, 204, 209)',
                                WebkitBoxAlign: 'center',
                                alignItems: 'center',
                                borderRadius: '10px',
                                padding: '4px 10px',
                                marginBottom: '2px',
                                position: 'relative',
                                fontSize: '14.222px',
                                lineHeight: '16.9px',
                              }}
                            >
                              <span
                                style={{
                                  boxSizing: 'border-box',
                                  borderWidth: '0px',
                                  borderStyle: 'solid',
                                  borderColor: 'rgb(199, 204, 209)',
                                  width: '1.625rem',
                                  height: '26px',
                                  display: 'flex',
                                  WebkitBoxAlign: 'center',
                                  alignItems: 'center',
                                  WebkitBoxPack: 'center',
                                  justifyContent: 'center',
                                  marginRight: '8px',
                                  transform: 'matrix(0.9, 0, 0, 0.9, 0, 0)',
                                }}
                              >
                                <svg
                                  fill="currentColor"
                                  height="20"
                                  stroke-width="0"
                                  width="20"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    display: 'block',
                                    verticalAlign: 'middle',
                                    boxSizing: 'border-box',
                                    borderWidth: '0px',
                                    borderStyle: 'solid',
                                    borderColor: 'rgb(227, 229, 232)',
                                  }}
                                >
                                  <path
                                    d="M10 2.60986C8.41913 2.60986 7.13464 3.89435 7.13464 5.47522V6.60986H2.49997C1.97905 6.60986 1.55675 7.03216 1.55675 7.55308V15.9432C1.55675 16.4641 1.97905 16.8864 2.49997 16.8864H17.5C18.0209 16.8864 18.4432 16.4641 18.4432 15.9432V7.55308C18.4432 7.03216 18.0209 6.60986 17.5 6.60986H12.8654V5.47522C12.8654 2.91442 10.8458 0.609863 8 0.609863C5.15422 0.609863 3.13464 2.91442 3.13464 5.47522V6.60986H7.13464H10ZM10 4.47522C9.21783 4.47522 8.57124 5.12182 8.57124 5.90399V6.60986H11.4288V5.90399C11.4288 5.12182 10.7822 4.47522 10 4.47522ZM16.3265 8.60986H3.67224V15.9432H16.3265V8.60986ZM14.3265 11.6099V13.9432H5.67224V11.6099H14.3265Z"
                                    fill-rule="evenodd"
                                    style={{
                                      boxSizing: 'border-box',
                                      borderWidth: '0px',
                                      borderStyle: 'solid',
                                      borderColor: 'rgb(227, 229, 232)',
                                    }}
                                  ></path>
                                </svg>
                              </span>
                              Change Password
                            </div>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: '9px 20px 24px 32px',
                    boxSizing: 'border-box',
                    borderWidth: '0px',
                    borderStyle: 'solid',
                    borderColor: 'rgb(227, 229, 232)',
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',

                    alignSelf: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      boxSizing: 'border-box',
                      borderWidth: '0px',
                      borderStyle: 'solid',
                      borderColor: 'rgb(227, 229, 232)',
                      maxWidth: '900px',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        borderWidth: '0px',
                        borderStyle: 'solid',
                        borderColor: 'rgb(227, 229, 232)',
                        marginBottom: '20px',
                      }}
                    ></div>
                    <h1
                      style={{
                        padding: '0px',
                        margin: '0px 0px 4px',
                        fontSize: '22px',
                        fontWeight: 500,
                        boxSizing: 'border-box',
                        borderWidth: '0px',
                        borderStyle: 'solid',
                        borderColor: 'rgb(227, 229, 232)',
                        color: 'rgb(227, 229, 232)',
                        lineHeight: '32px',
                        marginBottom: '4px',
                      }}
                    >
                      Profile Account
                    </h1>
                    <div
                      style={{
                        gridTemplateColumns: '420.5px 420.5px',
                        columnGap: '40px',
                        boxSizing: 'border-box',
                        borderWidth: '0px',
                        borderStyle: 'solid',
                        borderColor: 'rgb(227, 229, 232)',
                        display: 'grid',
                      }}
                    >
                      <div
                        style={{
                          boxSizing: 'border-box',
                          borderWidth: '0px',
                          borderStyle: 'solid',
                          borderColor: 'rgb(227, 229, 232)',
                          marginBottom: '5px',
                          marginTop: '5px',
                        }}
                      >
                        <label
                          htmlFor="settings-username"
                          style={{
                            boxSizing: 'border-box',
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            borderColor: 'rgb(147, 153, 159)',
                            display: 'inline-block',
                            fontSize: '12.642px',
                            lineHeight: '15.2px',
                            color: 'rgb(147, 153, 159)',
                            textTransform: 'uppercase',
                            marginBottom: '5px',
                          }}
                        >
                          Name
                        </label>
                        <input
                          name="handle"
                          placeholder="Name"
                          value={name}
                          disabled
                          style={{
                            fontFamily:
                              'ui-sans-serif, -apple-system, "system-ui", "Segoe UI", "Helvetica Neue", Helvetica, "Apple Color Emoji", Arial, sans-serif',
                            fontFeatureSettings: 'normal',
                            fontVariationSettings: 'normal',
                            fontSize: '14.222px',
                            fontWeight: 400,
                            lineHeight: '21.333px',
                            color: 'rgb(227, 229, 232)',
                            margin: '0px',
                            padding: '6px 10px',
                            boxSizing: 'border-box',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'rgb(44, 48, 53)',
                            display: 'block',
                            width: '100%',
                            border: '1px solid rgb(44, 48, 53)',
                            background:
                              'rgb(28, 31, 34) none repeat scroll 0% 0% / auto padding-box border-box',
                            borderRadius: '10px',
                          }}
                        />
                      </div>

                      <div
                        style={{
                          boxSizing: 'border-box',
                          borderWidth: '0px',
                          borderStyle: 'solid',
                          borderColor: 'rgb(227, 229, 232)',
                          marginBottom: '5px',
                          marginTop: '5px',
                        }}
                      >
                        <label
                          htmlFor="settings-email"
                          style={{
                            boxSizing: 'border-box',
                            borderWidth: '0px',
                            borderStyle: 'solid',
                            borderColor: 'rgb(147, 153, 159)',
                            display: 'inline-block',
                            fontSize: '12.642px',
                            lineHeight: '15.2px',
                            color: 'rgb(147, 153, 159)',
                            textTransform: 'uppercase',
                            marginBottom: '5px',
                          }}
                        >
                          Email
                        </label>
                        <input
                          name="email"
                          placeholder="Email"
                          disabled
                          value={email}
                          style={{
                            fontFamily:
                              'ui-sans-serif, -apple-system, "system-ui", "Segoe UI", "Helvetica Neue", Helvetica, "Apple Color Emoji", Arial, sans-serif',
                            fontFeatureSettings: 'normal',
                            fontVariationSettings: 'normal',
                            fontSize: '14.222px',
                            fontWeight: 400,
                            lineHeight: '21.333px',
                            color: 'rgb(227, 229, 232)',
                            margin: '0px',
                            padding: '6px 10px',
                            boxSizing: 'border-box',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'rgb(44, 48, 53)',
                            display: 'block',
                            width: '100%',
                            border: '1px solid rgb(44, 48, 53)',
                            background:
                              'rgb(28, 31, 34) none repeat scroll 0% 0% / auto padding-box border-box',
                            borderRadius: '10px',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      boxSizing: 'border-box',
                      borderWidth: '0px',
                      borderStyle: 'solid',
                      borderColor: 'rgb(227, 229, 232)',
                      marginBottom: '40px',
                      marginTop: '100px',
                    }}
                  >
                    <button
                      type="submit"
                      style={{
                        margin: '0px',
                        padding: '8px 20px',
                        boxShadow: 'none',
                        cursor: 'pointer',
                        border: '0px none rgb(255, 255, 255)',
                        whiteSpace: 'nowrap',
                        outline: 'rgb(255, 255, 255) none 0px',
                        appearance: 'button',
                        backgroundColor: 'rgb(255, 45, 96)',
                        backgroundImage: 'none',
                        textTransform: 'uppercase',
                        fontFamily:
                          'ui-sans-serif, -apple-system, "system-ui", "Segoe UI", "Helvetica Neue", Helvetica, "Apple Color Emoji", Arial, sans-serif',
                        fontFeatureSettings: 'normal',
                        fontVariationSettings: 'normal',
                        fontSize: '15px',
                        fontWeight: 700,
                        lineHeight: '22.5px',
                        color: 'rgb(255, 255, 255)',
                        boxSizing: 'border-box',
                        borderWidth: '0px',
                        borderStyle: 'none',
                        borderColor: 'rgb(255, 255, 255)',
                        display: 'flex',
                        borderRadius: '10px',
                        background:
                          'rgb(255, 45, 96) none repeat scroll 0% 0% / auto padding-box border-box',
                        transition: 'background-color 0.1s ease-in 0s',
                      }}
                      onClick={onLogout}
                    >
                      Logout
                    </button>
                  </div>
                  <hr
                    style={{
                      border: '0px none rgb(227, 229, 232)',
                      height: '1px',
                      margin: '0px 0px 30px',
                      color: 'rgb(227, 229, 232)',
                      borderTopWidth: '0px',
                      boxSizing: 'border-box',
                      borderWidth: '0px',
                      borderStyle: 'none',
                      borderColor: 'rgb(227, 229, 232)',
                      backgroundColor: 'rgb(44, 48, 53)',
                      marginBottom: '30px',
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
