import React from 'react'
import Header from './views/Header'
import Home from './views/Home'
import Login from './views/Login'
import Register from './views/Register'
import Verify from './views/Verify'
import Forgotpassword from './views/Forgotpassword'
import Pricing from './views/Pricing'
import PaymentSuccess from './views/PaymentSuccess'
import PaymentCancelled from './views/PaymentCancelled'
import Profile from './views/Profile'
import ChangePassword from './views/ChangePassword'
import Privacy from './views/Privacy'
import Terms from './views/Terms'
import Contact from './views/Contact'
import Billing from './views/Billing'
import AppView from './views/AppView'
import AppViewEx from './views/AppViewEx'

import ReactGA from 'react-ga'
import { initializeApp } from 'firebase/app'
import { Helmet } from 'react-helmet'
import { getAnalytics } from 'firebase/analytics'

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Route, Switch } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css'

//core
import 'primereact/resources/primereact.min.css'
function App() {
  const firebaseConfig = {
    apiKey: 'AIzaSyAa30qG1QQPTRLTc4zH3ZmK8fgGTgnbdjY',

    authDomain: 'aiflowchartreact.firebaseapp.com',

    projectId: 'aiflowchartreact',

    storageBucket: 'aiflowchartreact.appspot.com',

    messagingSenderId: '1063856020294',

    appId: '1:1063856020294:web:13dcb62313c9b6a01c6347',

    measurementId: 'G-K1P9Y41F37',
  }
  const app = initializeApp(firebaseConfig)
  ReactGA.initialize('AW-10810690229')
  const analytics = getAnalytics(app)
  return (
    <div>
      <Helmet>
        <title>Generate ai diagrams</title>
        <meta
          name="description"
          content="Generate ai diagrams from from text,pdf or images"
        />
      </Helmet>
      <PrimeReactProvider>
        <Header />
        <GoogleOAuthProvider clientId="143283423494-ohanri9fs6cgb7ndgn94ms4qhvmucaa6.apps.googleusercontent.com">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/verify" component={Verify} />
            <Route path="/forgot" component={Forgotpassword} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/paymentsuccess" component={PaymentSuccess} />
            <Route path="/paymentcancelled" component={PaymentCancelled} />
            <Route path="/profile" component={Profile} />
            <Route path="/changepassword" component={ChangePassword} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/contact" component={Contact} />
            <Route path="/billing" component={Billing} />
            <Route path="/legacy" component={AppView} />
            <Route path="/newverion" component={AppViewEx} />
          </Switch>
        </GoogleOAuthProvider>
      </PrimeReactProvider>
    </div>
  )
}

export default App
