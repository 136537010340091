import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
const MessageBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #2d2d2d;
  border-radius: 8px;
  padding: 8px 16px;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
`

const Icon = styled.span`
  color: #8e8e8e;
  font-size: 20px;
  cursor: pointer;
  flex-shrink: 0;
`

const AttachmentIcon = styled(Icon)`
  margin-right: 12px;
`

const MessageInput = styled.textarea`
  flex-grow: 1;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  outline: none;
  resize: none;
  overflow-y: auto;
  min-height: 20px;
  max-height: 100px;
  line-height: 20px;
  padding: 0;
  margin: 0;

  &::placeholder {
    color: #8e8e8e;
  }
`

const FilePreview = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #3d3d3d;
`

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const PDFIcon = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
`

const DeleteFile = styled.button`
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 10%;
  width: 10px;
  height: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
  }
`

const HiddenFileInput = styled.input`
  display: none;
`

const ActionButton = styled.button`
  background-color: #4a4a4a;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  margin-left: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  flex-shrink: 0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5a5a5a;
  }
`

const MessageInputBar = ({ onSend, onPause, isProcessing }) => {
  const [message, setMessage] = useState('')
  const [file, setFile] = useState(null)
  const textareaRef = useRef(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    adjustTextareaHeight()
  }, [message])

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`
    }
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = (e) => {
          setFile({
            file: selectedFile,
            preview: e.target.result,
            type: 'image',
          })
        }
        reader.readAsDataURL(selectedFile)
      } else if (selectedFile.type === 'application/pdf') {
        setFile({
          file: selectedFile,
          type: 'pdf',
        })
      }
    }
  }

  const deleteFile = () => {
    setFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleAction = () => {
    if (isProcessing) {
      onPause()
    } else if (onSend && (message.trim() || file)) {
      onSend({
        message: message.trim(),
        file: file ? file.file : null,
      })
      setMessage('')
      setFile(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  return (
    <MessageBar>
      <FilePreview show={!!file}>
        {file?.type === 'image' ? (
          <PreviewImage src={file.preview} alt="File preview" />
        ) : file?.type === 'pdf' ? (
          <PDFIcon>PDF</PDFIcon>
        ) : null}
        <DeleteFile onClick={deleteFile}>×</DeleteFile>
      </FilePreview>
      {!file && (
        <label htmlFor="file-input">
          <AttachmentIcon>&#128206;</AttachmentIcon>
        </label>
      )}
      <HiddenFileInput
        id="file-input"
        type="file"
        accept="image/*,application/pdf"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <MessageInput
        ref={textareaRef}
        value={message}
        onChange={handleMessageChange}
        placeholder="Please provide your query here (e.g., 'Exploration of Android Architecture') or  paste  blog content."
        rows={1}
      />
      <ActionButton onClick={handleAction}>
        {isProcessing ? '⏸' : '➤'}
      </ActionButton>
    </MessageBar>
  )
}

export default MessageInputBar
