import React from 'react'

function LandingPage() {
  return (
    <main className="flex-grow-1">
      {/* Hero */}
      <section
        className="hero-section style-1 overflow-hidden bg-dark py-10 py-lg-15"
        data-bs-theme="dark"
      >
        <div className="container">
          <div className="row justify-center">
            <div className="col-lg-9">
              <div className="text-center">
                <div className="position-relative z-1">
                  <p className="text-primary-dark" data-aos="fade-up-sm">
                    Best AI Diagram Tool
                  </p>
                  <h1
                    className="text-white mb-8"
                    data-aos="fade-up-sm"
                    data-aos-delay={150}
                  >
                    Generate Flowchart diagram 10X faster <br />
                    <span
                      className="fw-bold text-gradient-2 typed-animation"
                      data-strings='["Technical Writing", "News Article", "SEO Content", "Product Description"]'
                    >
                      Convert text to Flowchart
                    </span>
                  </h1>
                  <a
                    href="/login"
                    className="btn btn-lg btn-gradient-1"
                    data-aos="fade-up-sm"
                    data-aos-delay={200}
                  >
                    Start Generate
                  </a>
                </div>
                <div data-aos="fade-up-sm" data-aos-delay={300}>
                  <div className="image-with-shape">
                    <img
                      src="assets/images/shapes/blurry-shape-1.svg"
                      alt=""
                      className="shape animate-scale"
                    />
                    <div className="mt-12 rounded-5 animation-btn animated-ui shadow-lg overflow-hidden position-relative z-1">
                      <img
                        className="img-fluid d-inline-block"
                        src="assets/images/screens/newscreenai.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <ul className="d-flex flex-wrap gap-4 gap-md-8 gap-lg-10 align-center justify-center mt-8 mb-0">
                  <li>Diagrams from blogs</li>
                  <li>Diagrams from pdf files</li>
                  <li>Diagrams from images</li>
                </ul>
                {/* <div className="d-flex gap-8 align-center justify-center mt-12 review-badges">
                  <img
                    className="img-fluid"
                    src="assets/images/review-logos/trustpilot_reviews.svg"
                    alt=""
                  />
                  <img
                    className="img-fluid"
                    src="assets/images/review-logos/capterra_reviews.svg"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Features */}
      <section className="py-10 py-lg-15">
        <div className="container">
          <div className="text-center mb-18">
            <h1
              className="mb-0 text-white"
              data-aos="fade-up-sm"
              data-aos-delay={50}
            >
              Always Generate Creative and Unique Diagrams,{' '}
              <br className="d-none d-lg-block" />
              powered by chatgpt
            </h1>
          </div>
          <div className="row g-6 g-xl-14">
            <div
              className="col-lg-4"
              data-aos="fade-up-sm"
              data-aos-delay={200}
            >
              <div className="d-flex flex-column gap-6 flex-lg-row">
                <div className="icon w-14 h-14 flex-shrink-0 d-flex align-center justify-center rounded-3 p-2 border bg-gradient-3 text-primary-dark border-white border-opacity-10">
                  <svg
                    className="w-full h-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 40 40"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                    >
                      <path d="M30.167 10c-1.833 4.855-3.167 8.188-4 10m0 0c-3.132 6.813-6.188 10-10 10-4 0-8-4-8-10s4-10 8-10c3.778 0 6.892 3.31 10 10Zm0 0c.853 1.837 2.187 5.17 4 10" />
                    </g>
                  </svg>
                </div>
                <div className="content">
                  <h4 className="mb-4 text-white">Generates Unique diagrams</h4>
                  <p>
                    This application will generate unique diagrams.If you dont
                    like generated diagram recreate again.Then again it will
                    generate new diagram
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up-sm"
              data-aos-delay={250}
            >
              <div className="d-flex flex-column gap-6 flex-lg-row">
                <div className="icon w-14 h-14 flex-shrink-0 d-flex align-center justify-center rounded-3 p-2 border bg-gradient-3 text-primary-dark border-white border-opacity-10">
                  <svg
                    className="w-full h-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 40 40"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                    >
                      <path d="M3.333 20 20 32.37 36.666 20" />
                      <path d="M11.667 15 20 21.667 28.334 15m-10.001-5L20 11.333 21.666 10 20 8.666 18.333 10Z" />
                    </g>
                  </svg>
                </div>
                <div className="content">
                  <h4 className="mb-4 text-white">Diagrams with explanation</h4>
                  <p>
                    This tool will help you to generate diagrams with
                    explanation.Which means tools will explain each steps of the
                    diagram
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up-sm"
              data-aos-delay={300}
            >
              <div className="d-flex flex-column gap-6 flex-lg-row">
                <div className="icon w-14 h-14 flex-shrink-0 d-flex align-center justify-center rounded-3 p-2 border bg-gradient-3 text-primary-dark border-white border-opacity-10">
                  <svg
                    className="w-full h-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 40 40"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                    >
                      <path d="M10 29.334 6.667 27.5v-4.166m0-6.668V12.5L10 10.666m6.667-3.833L20 5l3.334 1.833M30 10.666l3.333 1.834v4.166m0 6.668V27.5L30 29.367m-6.666 3.799L20 35l-3.333-1.834M20 20l3.333-1.834M30 14.333l3.333-1.833M20 20v4.167m0 6.667V35m0-15-3.333-1.867M10 14.333 6.667 12.5" />
                    </g>
                  </svg>
                </div>
                <div className="content">
                  <h4 className="mb-4 text-white">Themes</h4>
                  <p>
                    Many themes available.One customizable theme also
                    available.which means you can change diagrams colors
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-12">
            <a href="/login" className="btn btn-primary-dark">
              Get Started Free
            </a>
          </div>
        </div>
      </section>
      {/* Features */}
      <section className="py-10 py-lg-15 @extraClassList">
        <div className="container">
          <div className="row g-6 gx-lg-14 gx-xl-20 align-center mt-10 flex-row-reverse">
            <div
              className="col-lg-6 col-xl-6"
              data-aos="fade-up-sm"
              data-aos-delay={150}
            >
              <div className="content">
                <p className="text-primary-dark">Diverse Diagram Generation</p>
                <h1 className="text-white mb-8">
                  Diverse Diagram Generation for Enhanced Creativity
                </h1>
                <p className="mb-6">
                  Unlimited Creativity: Our AI flowchart tool generates unique
                  diagrams every time you use it, providing you with a wide
                  range of creative options. Multiple Diagram Types: Create
                  flowcharts, graph diagrams, sequence diagrams, and pie charts
                  effortlessly, all from a single tool.
                </p>
                <a
                  href="login.html"
                  className="arrow-link arrow-link-primary-dark text-primary-dark gap-3"
                >
                  <span>Request A Demo</span>
                  <svg
                    className="icon"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 12.6667L12.6667 4M12.6667 4V12.32M12.6667 4H4.34667"
                      stroke="currentColor"
                      strokeWidth="1.21"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up-sm"
              data-aos-delay={250}
            >
              <div className="feature-img">
                <img
                  src="assets/images/screens/feature1.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row g-6 gx-lg-14 gx-xl-20 align-center mt-10">
            <div
              className="col-lg-6"
              data-aos="fade-up-sm"
              data-aos-delay={150}
            >
              <div className="content">
                <p className="text-primary-dark">Features 3</p>
                <h1 className="text-white mb-8">
                  Versatile Source Compatibility for Diagram Generation with
                  <span className="text-primary-dark">AI Flowchart</span>.
                </h1>
                <p className="mb-6">
                  Your Satisfaction is Our Priority: Recreate Diagrams as Needed
                </p>
                <ul className="list-unstyled list-check mb-8">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                      className="icon"
                    >
                      <g>
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="m3.75 9 3.75 3.75 7.5-7.5"
                        />
                      </g>
                    </svg>
                    <span>
                      Generate diagrams from various sources, including text
                      data, PDF files, or even blog content.
                    </span>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                      className="icon"
                    >
                      <g>
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="m3.75 9 3.75 3.75 7.5-7.5"
                        />
                      </g>
                    </svg>
                    <span>
                      Not satisfied with the first set of diagrams? No problem.
                      Easily recreate new ones until you're completely satisfied
                    </span>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                      className="icon"
                    >
                      <g>
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="m3.75 9 3.75 3.75 7.5-7.5"
                        />
                      </g>
                    </svg>
                    <span>
                      We empower you to explore different visual representations
                      until you find the perfect fit for your needs.
                    </span>
                  </li>
                </ul>
                <a
                  href="login.html"
                  className="arrow-link arrow-link-primary-dark text-primary-dark gap-3"
                >
                  <span>Get Started Free</span>
                  <svg
                    className="icon"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 12.6667L12.6667 4M12.6667 4V12.32M12.6667 4H4.34667"
                      stroke="currentColor"
                      strokeWidth="1.21"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up-sm"
              data-aos-delay={250}
            >
              <div className="feature-img">
                <img
                  src="assets/images/screens/feature2.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <section className="py-10 py-lg-15">
        <div className="container">
          <div className="row justify-center mb-18">
            <div className="col-lg-10">
              <div className="text-center">
                <h1
                  className="mb-0 text-white"
                  data-aos="fade-up-sm"
                  data-aos-delay={50}
                >
                  Questions About our AIFlowchart?{' '}
                  <br className="d-none d-md-block" />
                  We have Answers!
                </h1>
              </div>
            </div>
          </div>
          <div className="row justify-center">
            <div
              className="col-lg-8"
              data-aos="fade-up-sm"
              data-aos-delay={100}
            >
              <div
                className="accordion accordion-flush d-flex flex-column gap-6"
                id="faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapseOne"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      <span className="icon" />
                      What Is AI Flowchart Diagram Tool?
                    </button>
                  </h2>
                  <div
                    id="faq-collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      The AI Flowchart Diagram Tool is a powerful and innovative
                      tool designed to assist users in creating various types of
                      diagrams using artificial intelligence (AI) technology. It
                      utilizes the Chat GPT API to generate diagrams seamlessly.
                      With this tool, users can easily create flowcharts, graph
                      diagrams, sequence diagrams, and pie charts from different
                      data sources such as user queries, blog content, PDF
                      files, and images. It simplifies the diagram creation
                      process and enhances productivity, making it an
                      indispensable tool for individuals and businesses alike.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapseTwo"
                      aria-expanded="false"
                      aria-controls="faq-collapseTwo"
                    >
                      <span className="icon" />
                      How can I generate diagrams from text, PDF files, or blog
                      content using your AI flowchart tool?
                    </button>
                  </h2>
                  <div
                    id="faq-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      Our AI flowchart tool offers a convenient way to create
                      diagrams from text, PDF files, or blog content. You can
                      simply input the relevant text, upload your PDF files, or
                      Copy paste the blog content, and our tool will process
                      this data to generate the requested diagrams. This feature
                      is particularly useful for visualizing information from
                      written content.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapseThree"
                      aria-expanded="false"
                      aria-controls="faq-collapseThree"
                    >
                      <span className="icon" />
                      How accurate are the diagrams generated by your AI tool?
                    </button>
                  </h2>
                  <div
                    id="faq-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      Our AI flowchart tool leverages the power of the Chat GPT
                      API to generate diagrams, which ensures a high level of
                      accuracy. However, the accuracy also depends on the
                      quality and clarity of the input data. We recommend
                      providing well-structured and clear data to achieve the
                      best results.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapseFour"
                      aria-expanded="false"
                      aria-controls="faq-collapseFour"
                    >
                      <span className="icon" />
                      What types of diagrams can I create with your AI flowchart
                      tool?
                    </button>
                  </h2>
                  <div
                    id="faq-collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      Our AI flowchart tool can generate five types of diagrams:
                      flowcharts, graph diagrams, sequence diagrams, and pie
                      charts. You can choose the type that best suits your needs
                      and input the relevant data to create these diagrams.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapseFive"
                      aria-expanded="false"
                      aria-controls="faq-collapseFive"
                    >
                      <span className="icon" />
                      How does the subscription process work?
                    </button>
                  </h2>
                  <div
                    id="faq-collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      To use our AI flowchart tool and generate diagrams, you
                      need to subscribe to one of our pricing plans. We offer
                      various subscription options to cater to different usage
                      levels. Once subscribed, you will have access to the
                      tool's features based on your selected plan.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapseFive"
                      aria-expanded="false"
                      aria-controls="faq-collapseFive"
                    >
                      <span className="icon" />
                      Can I generate unique diagrams every time I use the AI
                      flowchart tool? What if I'm not satisfied with the
                      generated diagrams?
                    </button>
                  </h2>
                  <div
                    id="faq-collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      Absolutely! Our AI flowchart tool is designed to provide
                      flexibility and creativity. It generates unique diagrams
                      each time you use it, ensuring that you have a variety of
                      options to choose from. If you're not satisfied with the
                      initial set of diagrams generated, you can easily recreate
                      new ones until you find the perfect fit for your needs. We
                      aim to give you the freedom to explore different visual
                      representations until you're completely satisfied with the
                      results.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CTA */}
      <section
        className="cta-section py-10 py-lg-15"
        data-aos="fade-up-sm"
        data-aos-offset={150}
      >
        <div className="container">
          <div className="rounded-5 border position-relative z-1 bg-dark-blue-4">
            <div className="animate-scale position-absolute w-full h-full z-n1">
              <img
                src="assets/images/shapes/blurry-shape-4.svg"
                alt=""
                className="bg-shape img-fluid"
              />
            </div>
            <div className="row justify-center">
              <div className="col-lg-10">
                <div className="text-center pt-6 px-6 pt-md-10 px-md-10 pt-lg-18 px-lg-18">
                  <h2 className="mb-6 text-white">
                    Using
                    <span className="text-primary-dark"> AI Flowchart </span>
                    you can save hours each week creating diagrams.
                  </h2>
                  <a href="/login" className="btn btn-primary-dark">
                    Get Started Free
                  </a>
                  <div className="cta-image-container mt-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 34 90"
                      className="text-primary-dark arrow-shape"
                    >
                      <path
                        fill="currentColor"
                        d="M3.724 2.303c8.095 4.54 13.968 13.648 16.408 22.434 2.336 8.415 2.426 20.276-5.705 25.79-2.961 2.01-7.092 2.24-8.781-1.444-1.571-3.422.29-7.096 3.683-8.452 9.162-3.663 16.334 8.02 18.234 15.324a30.563 30.563 0 0 1 .279 14.195c-.952 4.334-2.866 9.283-6.298 12.254-.494.427-1.3-.29-.971-.84 1.77-2.928 3.677-5.571 4.79-8.851 1.155-3.405 1.62-7.048 1.44-10.626-.358-7.103-3.568-15.745-10.125-19.354-3.476-1.912-10.316-1.353-10.055 3.973.107 2.158 1.647 4.035 3.933 3.81 2.086-.209 4.001-1.766 5.333-3.279 5.427-6.16 4.857-15.89 2.67-23.215a39.21 39.21 0 0 0-5.682-11.577c-2.69-3.76-6.017-6.61-9.592-9.472-.35-.277.039-.896.44-.67Z"
                      />
                      <path
                        fill="currentColor"
                        d="M1.562.977c9.931 2.79 17.058 11.508 19.312 21.4 1.085 4.762 1.187 9.7.548 14.54-.653 4.937-1.854 10.549-4.949 14.589-2.156 2.82-7.305 5.961-10.266 2.388-2.608-3.142-2.18-9.094.45-12.093 2.945-3.356 8.048-2.969 11.491-.718 4.112 2.688 6.675 7.596 8.265 12.12 3.48 9.905 2.395 21.33-3.11 30.327-.527.858-1.947.203-1.423-.676 3.935-6.565 5.559-14.253 4.688-21.84-.443-3.864-1.552-7.677-3.306-11.147-2.011-3.973-5.078-8.396-9.854-8.994-5.273-.66-7.99 4.089-7.3 8.82.403 2.76 1.938 4.99 5.042 4.079 2.519-.74 4.35-3.051 5.51-5.296 3.708-7.194 4.563-16.802 3.066-24.658C17.848 13.969 11.217 4.92 1.373 1.995.736 1.812.917.797 1.563.977Z"
                      />
                      <path
                        fill="currentColor"
                        d="M21.218 73.052c.375 2.062.446 4.204.634 6.29.088.987.18 1.975.266 2.964.04.457-.025 2.873.383 3.085.21.11 2.177-1.456 2.452-1.64l2.452-1.641c1.595-1.065 3.329-2.678 5.205-3.148.671-.169 1.174.542.746 1.106-.792 1.05-1.99 1.644-3.08 2.36-1.23.812-2.464 1.62-3.695 2.432-1.142.748-3.43 3.037-4.974 2.3-1.476-.7-.955-3.793-1.042-5.105-.198-2.945-.602-5.957-.531-8.906a.595.595 0 0 1 1.184-.097Z"
                      />
                      <path
                        fill="currentColor"
                        d="M21.773 73.169c-.032 2.254-.679 4.55-.972 6.789-.338 2.597-.601 5.224-.564 7.844-.465-.225-.933-.454-1.398-.68a76.772 76.772 0 0 0 6.002-4.227c1.876-1.465 3.568-3.521 5.632-4.678.6-.336 1.581.26 1.137.983-1.181 1.924-3.415 3.456-5.165 4.844a64.808 64.808 0 0 1-6.607 4.574c-.694.421-1.465-.14-1.385-.91.27-2.565.462-5.128.849-7.683.348-2.297.616-4.895 1.59-7.019.19-.398.887-.308.88.163Z"
                      />
                      <path
                        fill="currentColor"
                        d="M22.85 71.546c-.873 5.764-1.778 11.525-2.588 17.298-.462-.304-.922-.605-1.384-.91 2.439-1.254 4.864-2.527 7.207-3.954 2.158-1.317 4.212-3.127 6.536-4.109.733-.31 1.331.688.841 1.25-1.713 1.972-4.396 3.318-6.619 4.634-2.326 1.378-4.712 2.663-7.172 3.78-.633.287-1.294-.395-1.174-1.015 1.098-5.725 2.104-11.464 3.137-17.2.137-.79 1.337-.563 1.215.226Z"
                      />
                    </svg>
                    <div className="cta-img rounded-top-4">
                      <img
                        src="assets/images/screens/screen_bottom.png"
                        alt=""
                        className="img-fluid w-full h-full object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default LandingPage
