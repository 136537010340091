import React, { useState } from 'react'
import { BUTTON_TEXT } from './constants' // Import button text constants

const TagButton = ({ label, onClick, isSelected }) => {
  const [clicked, setClicked] = useState(false)
  const handleClick = () => {
    setClicked(!clicked)
  }

  const buttonStyle = {
    padding: '5px 20px', // Reduce the top and bottom padding to reduce the height
    margin: '10px', // Add margin around the button
    border: '2px solid rgb(255, 45, 96)',
    backgroundColor: isSelected ? 'rgb(255, 45, 96)' : 'transparent',
    color: isSelected ? '#fff' : 'rgb(255, 45, 96)',
    cursor: 'pointer',
    borderRadius: '20px', // Set the border radius to 10px (adjust as needed)
    transition: 'background-color 0.3s, color 0.3s',
  }

  return (
    <button style={buttonStyle} onClick={onClick}>
      {label}
    </button>
  )
}

export default TagButton
