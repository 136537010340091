import React from 'react'

const RoundButton = ({ color, onClick, children }) => {
  const buttonStyle = {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    marginRight: '10px',
    backgroundColor: color || '#007bff', // Default color is blue if not provided
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  }

  return (
    <div style={buttonStyle} onClick={onClick}>
      {children}
    </div>
  )
}

export default RoundButton
